import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Container, Grid, Header, SpaceBetween, FormField, Input }
  from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import { Auth } from 'aws-amplify';
import AppConstants from '../../_Constants/AppConstants';
import { logToConsole } from '../../Util';

const EMPTY_USERNAME = 'Username cannot be empty';

class ForgotPassword extends React.Component {

  state = {
    username: AppConstants.EMPTY,
    error: AppConstants.EMPTY
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.detail.value, error: AppConstants.EMPTY });
  }

  handleSendCode = () => {
    Auth.forgotPassword(this.state.username)
    .then(response => {
      logToConsole('Sent verification code successfully');
      this.props.sendcode(this.state.username);
      this.props.handleResetPasswordHasCode();
    })
    .catch( err => {
      var message;
      if (typeof err === 'string') {
        message = err;
      } else {
        message = err.message;
      }
      this.setState({ error: message });
      logToConsole('Error sending verification code: ' + message);
    });
  }

  handleResetPasswordHasCode = () => {
    if (!this.state.username) {
      this.setState({ error: EMPTY_USERNAME });
    } else {
      this.props.resetpassword(this.state.username);
    }
  }

  forgotPasswordForm() {
    return (
      <SpaceBetween direction="vertical" size="m">
        <FormField label={<b>Email</b>}>
          <Input
              disableBrowserAutocorrect
              type="email"
              value={this.state.username}
              onChange={this.handleChange('username')}
          />
        </FormField>
        <Button fullWidth
                variant="primary"
                onClick={this.handleSendCode}>
          Send Code
        </Button>
        <FormField errorText={this.state.error}/>
      </SpaceBetween>
    )
  }

  render() {
    return(
      <Grid gridDefinition={[{ colspan: 12 }, { colspan: 12}]}>
        <div style={{ height: "10vh" }}></div>
        <div style={{ width: "400px", margin: "0 auto" }}>
          <SpaceBetween direction="vertical" size="xl">
            <Box textAlign="center" variant="h1">Open Automation Kit</Box>
            <Container>
              <SpaceBetween direction="vertical" size="m">
                <Header variant="h1">Password assistance</Header>
                <Box>Enter the email address associated with your Open Automation Kit account.</Box>
                { this.forgotPasswordForm() }
              </SpaceBetween>
            </Container>
          </SpaceBetween>
        </div>
      </Grid>
    )
  }
}

ForgotPassword.propTypes = {
  sendcode: PropTypes.func.isRequired,
  resetpassword: PropTypes.func.isRequired
};

export default ForgotPassword;
