import React from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { aqtStore } from '../../Components/State/Store';
import { setSession } from '../../Components/State/Actions';
import { isEmpty } from 'lodash';
import AppConstants from '../../_Constants/AppConstants';
import { logToConsole } from '../../Util';
import authActions from 'redux/actions/auth';
import { TopNavigation }
  from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';

class HeadBar extends React.Component {

  state = {
    signedIn: false
  }

  // TODO: Remoe this after App refactor
  componentDidMount() {
    this.unsubscribe = aqtStore.subscribe(this.aqtStateHandler);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  aqtStateHandler = () => {
    // TODO: Temporary sign out till this file mess is refactored
    const userSessionObj = aqtStore.getState().session;
    if (!isEmpty(userSessionObj) && !isEmpty(userSessionObj.idToken)) {
      if (!isEmpty(userSessionObj.idToken.payload)) {
        this.userName = userSessionObj.idToken.payload.email;
      } else {
        this.userName = userSessionObj.username;
      }
      this.setState({ signedIn: true });
    }
  }

  /**
   * Logout component for the head bar
   */
  getLogoutComponent = (userName = AppConstants.EMPTY) => {
    return [
      {
        type: "button",
        text: "Dashboard",
        href: "/dashboard",
      },
      {
        type: "button",
        text: "Labs",
        href: "/labs",
      },
      {
        type: "button",
        text: "Tests",
        href: "/tests",
      },
      {
        type: "button",
        text: "Live runs",
        href: "/liveRuns",
      },
      {
        type: "button",
        text: "Playground",
        href: "/playground",
      },
      {
      type: "menu-dropdown",
      text: userName,
      iconName: "user-profile",
      onItemClick: (event) => { if (event.detail.id === "signout") this.handleSignOut(); },
      items: [
        {
          id: "support-group",
          text: "Support",
          items: [
            {
              id: "documentation",
              text: "Documentation",
              href: "#",
              external: true,
              externalIconAriaLabel: " (opens in new tab)"
            }
          ]
        },
        { id: "signout",
          text: "Sign out"
        }
      ]
    }];
  }

  /**
   * Function to trigger sign out is moved here from LogoutBar
   */
  handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        aqtStore.dispatch(setSession({}));
        this.props.setSession({});
        this.setState({ signedIn: false });
       })
      .catch(error => {
        logToConsole('Error signing out : ' + JSON.stringify(error));
      });
  };

  render() {
    return (
      <TopNavigation
        identity={{ title: "Open Automation Kit" }}
        utilities={ this.state.signedIn ? this.getLogoutComponent(this.userName) : []}
      />
    );
  }
}

export default connect(s => s, {
  setSession: authActions.setSession
})(HeadBar);
