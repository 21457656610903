import axios from 'axios';
import ApiConstants from '../../_Constants/ApiConstants';
import AppConstants from '../../_Constants/AppConstants';
import { aqtStore } from '../../Components/State/Store';
import { withHeader } from '../../Auth';
import { networkError, getSession, logToConsole } from '../../Util';

/**
 * Method to retrieve Release Notes from controller
 */
export function getReleaseNotes(num_of_record) {
    if (getSession() === AppConstants.SESSIONERR) {
        return new Promise(resolve => {
            resolve({
                error: AppConstants.SESSIONERR
            })
        });
    }
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    return axios.get(controllerEndpoint +
        ApiConstants.GET_RELEASE_NOTES +
        '?id=' + AppConstants.RELEASE_NOTES_ID_KEY +
        '&limit=' + num_of_record,
        withHeader(aqtStore.getState().session.idToken.jwtToken))
        .then(response => {
            logToConsole("Response From direct controller API");
            logToConsole(response);
            if (response.hasOwnProperty('data')) {
                return { releaseNotes: response.data ? response.data : {} };
            } else {
                return { error: AppConstants.SERVERERR }
            }
        })
        .catch(error => {
            networkError(error, getReleaseNotes.name);
            return { error: AppConstants.NETWORKERR };
        });
}
