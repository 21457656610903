import React from 'react';
import axios from 'axios';
import Main from './Container/Main';
import Login from './Container/Login';
import ForgotPassword from './Container/Login/ForgotPassword';
import ResetPassword from './Container/Login/ResetPassword';
import ChangeInitialPassword from './Container/Login/ChangeInitialPassword';
import Amplify, { Auth } from 'aws-amplify';
import { aqtStore } from './Components/State/Store';
import { setSession, setEnvironment } from './Components/State/Actions';
import AppConstants from './_Constants/AppConstants';
import { connect } from 'react-redux'
import { isEmpty } from 'lodash';
import MENU_ITEMS from './Container/Main/MenuConfig';
import { AppLayout, Spinner, SideNavigation }
  from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';

import * as authActions from './redux/actions/auth';

const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

class App extends React.Component {

  state = {
    isLoading: true,
    isSignedIn: false,
    sessionObj: {},
    isForgotPassword: false,
    isResetPassword: false,
    isChangeInitialPassword: false,
    username: AppConstants.EMPTY,
    password: AppConstants.EMPTY,
    user: {}
  };

  componentDidMount() {
    this.unsubscribe = aqtStore.subscribe(this.aqtStateHandler);
    return axios.get("/config.json").then(config => {
        if (config.data.loadLocal) {
          console.log('Loading local config');
          config.data = {
            aws_cognito_identity_pool_id: "us-west-2:c98e9171-4b3e-4385-92cc-3899a8886511",
            aws_cognito_region: "us-west-2",
            aws_sign_in_enabled: "enable",
            aws_user_pools: "enable",
            aws_user_pools_id: "us-west-2_nlUkid4Hr",
            aws_user_pools_mfa_type: "OFF",
            aws_user_pools_web_client_id: "4cbv1cl5mt14lumn38h0oo8oas",
            aqt_hex: "https://corp.aqths-fleet-lhs9uha9ic.iad.prod.hex.a2z.com",
            aqt_hex_gamma: "https://corp.aqths-fleet-hxwmdlf2k8.iad.gamma.hex.a2z.com",
            controllerEndpoint: "https://api.us-west-2.alpha.amazon-aqt.com",
            oauth: {
              domain: "aqt-alpha.auth.us-west-2.amazoncognito.com",
              redirectSignIn: "http://localhost:3000",
              redirectSignOut: "http://localhost:3000",
              scope: ["profile", "openid", "email"],
              responseType: "code"
            }
          }
        }
        // TODO: Remove this and use single store
        aqtStore.dispatch(setEnvironment(config.data));
        this.props.setEnvironment(config.data);
        Amplify.configure(config.data);
        return Auth.currentSession()
            .then( session => {
              // TODO: Remove this and use single store
              aqtStore.dispatch(setSession(session));
              this.props.setSession(session);
              this.setState({ isSignedIn: true, isLoading: false, sessionObj: session });
            })
            .catch( err => {
              this.setState({ isSignedIn: false, isLoading: false, sessionObj: {} });
            });
        }
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  aqtStateHandler = () => {
    // TODO: Temporary sign out till this file mess is refactored
    if (isEmpty(aqtStore.getState().session)) {
      this.handleSignOut();
    }
  }

  handleSignIn = (session) => {
    let sessionJsonString = JSON.stringify(session);
    if (sessionJsonString.includes(NEW_PASSWORD_REQUIRED)) {
      this.setState({ isSignedIn: false, isChangeInitialPassword: true, user : session });
    } else {
      window.location.reload(true);
    }
    aqtStore.dispatch(setSession(session));
    this.props.setSession(session);
    this.setState({ isSignedIn: true, sessionObj: session });
  }

  handleSignOut = () => {
    // TODO: Handle this in re-factor
    this.setState({ isSignedIn: false, sessionObj: {} });
  }

  handleForgotPassword = () => {
    this.setState({ isForgotPassword: true });
  }

  handleResetPasswordBackToLogin = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false });
  }

  handleResetPasswordSubmit = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false });
  }

  handleSendCode = (username) => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: true, username: username });
  }

  handleResetPasswordHaseCode = (username) => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: true, username: username });
  }

  handleChangePasswordBackToLogin = () => {
    this.setState({ isForgotPassword: false, isSignedIn: false, isResetPassword: false, isChangeInitialPassword: false });
  }

  /**
   * Render pages related to authentication
   */
  renderAuth = () => {
    const { isChangeInitialPassword, isResetPassword, isForgotPassword } = this.state;
    if (isForgotPassword && !isResetPassword && !isChangeInitialPassword) {
      return (
        <ForgotPassword
          sendcode = { this.handleSendCode }
          resetpassword={ this.handleResetPasswordHaseCode }
        />
      );
    } else if (isResetPassword && !isChangeInitialPassword) {
      return (
        <ResetPassword
          params={{ username: this.state.username }}
          backtologin = { this.handleResetPasswordBackToLogin }
          submit = {this.handleResetPasswordSubmit}
        />
      );
    } else if (isChangeInitialPassword) {
      return (
        <ChangeInitialPassword
          params={{ user: this.state.user }}
          backtologin = { this.handleChangePasswordBackToLogin }
          submit = { this.handleChangePasswordBackToLogin }
        />
      )
    }

    return (
      <Login
        authenticate = {this.handleSignIn}
        forgotpassword = {this.handleForgotPassword}
      />
    );
  }

  render() {
    const { isLoading, isSignedIn, isForgotPassword, isResetPassword, isChangeInitialPassword } = this.state;

    if (isLoading) {
        return <div className="awsui-util-t-c"><Spinner size="large"/></div>;
    }

    if (!isSignedIn || isForgotPassword || isResetPassword || isChangeInitialPassword) {
      return this.renderAuth();
    }

    return (<AppLayout
        navigationHide
        toolsHide
        disableBodyScroll
        disableContentPaddings
        headerSelector=".header"
        footerSelector=".footer"
        // navigation={
        //     <SideNavigation
        //         activeHref={ window.location.pathname === '/' ? '/labs' : window.location.pathname }
        //         header={{ text: "OAK" }}
        //         items={ MENU_ITEMS }/>
        // }
        content={
            <Main />
        }
    />);
  }
}

export default connect(s => s, {
  setEnvironment: authActions.setEnvironment,
  setSession: authActions.setSession,
})(App);
