import React, {useEffect, useState} from 'react';
import { HexClient as client } from './client';
import Chat from "./chat";
import DeviceSelect from "./deviceSelect";
import {
  Grid,
  Container,
  Alert,
  Box,
  ContentLayout,
  Header,
  Button,
  ExpandableSection,
  SpaceBetween
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import Api from "./api";
import * as ld from 'lodash';
import { deviceInfo } from "./util";
import Panel from "./panel";

function Playground() {
  const [corpAccess, setCorpAccess] = useState(null);
  const [activeDevice, setActiveDevice] = useState(null);
  const [errors, setErrors] = useState([]);
  const [apl, setApl] = useState(false);

  useEffect(() => {
      // Cleanup function
      return () => {
          if (activeDevice) {
            activeDevice.endstream();
          }
      };
  }, []);

  useEffect(() => {
    let ping = async () => {
      try {
        if (corpAccess === null) {
          let resp = client.ping();
          setCorpAccess(ld.get(await resp, 'status', '') === 200);
        }
      } catch (err) {
        setCorpAccess(false);
        setErrors([...errors, 'Cannot verify corp connectivity to OAK']);
      }
    }
    ping();
  });

  return (
    !corpAccess ? (
        <Alert type="warning">Access to Playground requires Corp access (VPN) and CID allowlisted in OAK</Alert>
    ) : (
          <div>
            { activeDevice ? (
              <Grid
                gridDefinition={[{colspan: 10, offset:{ xxs:1 }}]}
                disableGutters
              >
                <Container>
                  <ContentLayout
                    defaultPadding
                    disableOverlap
                    headerVariant="divider"
                    header={
                      <Header
                        variant="h2"
                        description="Interact with Alexa devices remotely and access API data"
                        actions={
                           <Button onClick={() => {
                             activeDevice.endstream();
                             setActiveDevice(null);
                             setApl(false);
                           }}>Back</Button>
                        }
                      >
                        Playground
                      </Header>
                    }
                  >
                    <ExpandableSection headerText="Device Info">
                      { deviceInfo(activeDevice) }
                    </ExpandableSection>
                    { activeDevice.inOak ? (
                      <SpaceBetween size="xs">
                        <Grid gridDefinition={[{colspan: 8}, {colspan: 4}]}>
                          <div id="chatcontent" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '70vh',
                            borderTop: '0px',
                            overflowY: 'auto',
                            padding: '1px',
                            background: '#f2f3f3',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            marginTop: '6px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                          }}>
                            <div style={{ flex: 1, overflowY: 'auto', padding: '8px' }}>
                              <Chat device={activeDevice}/>
                            </div>
                            { apl && (
                              <div style={{display:'flex', justifyContent: 'center', padding: '4px'}} id="apl" />
                            )}
                            <div>
                              <Panel device={activeDevice} setApl={setApl} />
                            </div>
                          </div>
                          <div id="apicontent" style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '70vh',
                            borderTop: '0px',
                            overflowY: 'auto',
                            padding: '0px',
                            background: '#f2f3f3',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            marginTop: '6px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                          }}>
                            <div><Container><Box
                              color="text-status-inactive"
                              fontSize="heading-xs"
                              fontWeight="bold"
                              textAlign="center"
                            >
                              API Logs
                            </Box></Container></div>
                            <div style={{ flex: 1, overflowY: 'auto', padding: '8px' }}>
                              <Api device={activeDevice} setApl={setApl}/>
                            </div>
                          </div>
                        </Grid>
                      </SpaceBetween>
                      ) : (
                        <Box margin="xxl" padding="xxl" fontSize="heading-m">
                          Switch the device to Alexa (with OAK) to use the Playground feature
                        </Box>
                      )
                    }
                  </ContentLayout>
                </Container>
              </Grid>
            ) : (
              <Grid
                gridDefinition={[{ colspan: 8, offset:{ xxs:2 } }]}
                disableGutters
              >
                <Container>
                  <DeviceSelect onSelect={d => { setActiveDevice(d); setApl(false); }}/>
                </Container>
              </Grid>
              )
            }
          </div>
    )
  );
}

export default React.memo(Playground);
