import axios from 'axios';
import ApiConstants from '../../_Constants/ApiConstants';
import AppConstants from '../../_Constants/AppConstants';
import SyncResourceConstants from '../../_Constants/SyncResourceConstants';
import { aqtStore } from '../../Components/State/Store';
import { withHeader } from '../../Auth';
import { networkError, getSession, logToConsole } from '../../Util';
import {
  PLUGIN_NOT_INSTALLED,
  PLUGIN_INSTALLED,
  RASPI_OFFLINE,
  ACTION_INSTALL_PLUGIN,
  ACTION_TIMEOUT,
  PAYLOAD_INSTALL_PLUGIN,
  GET_HOTSPOT_CONFIG_PAYLOAD,
  CREATE_HOTSPOT_PAYLOAD,
  PLUGIN_ERROR,
  RASPI_ONLINE,
  MQTT_TIMEOUT,
  GET_CONNECTED_DEVICES_PAYLOAD,
  PLUGIN_NOT_CONFIGURED,
} from '../../_Constants/AQTHotspotConstants';
import { v4 as uuidv4 } from 'uuid';
import ld from 'lodash';

export var jobSockets = {};

export function convertStrToJson(strObj) {
  if (strObj !== null && strObj.length > 0) {
    return (0, eval)('(' + strObj + ')');
  } else {
    return null;
  }
}

export function fetchLabsAndRasPis() {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  let labsAndThingsPromises = [];
  return axios.get(controllerEndpoint +
    ApiConstants.VIEW_GROUP_LIST_URL,
    withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(labs => {
      if (labs.hasOwnProperty('data')) {
        logToConsole('Labs: ' + JSON.stringify(labs.data));
        labs.data.forEach(labMap => {
          labsAndThingsPromises.push(
            axios.get(controllerEndpoint +
              ApiConstants.VIEW_GROUP_LIST_URL + '/' +
              labMap.id + '/' +
              ApiConstants.THINGS,
              withHeader(aqtStore.getState().session.idToken.jwtToken))
              .then(rasPis => {
                return {
                  lab: labMap,
                  rasPis: rasPis.data ? rasPis.data : []
                };
              })
              .catch(error => {
                networkError(error, fetchLabsAndRasPis.name);
                return {
                  error: AppConstants.NETWORKERR
                };
              })
          );
        });
        return labsAndThingsPromises;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      networkError(error, fetchLabsAndRasPis.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function fetchLabs() {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
    ApiConstants.VIEW_GROUP_LIST_URL,
    withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(labs => {
      if (labs.hasOwnProperty('data')) {
        logToConsole('Labs: ' + JSON.stringify(labs.data));
        return labs.data;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      networkError(error, fetchLabs.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function fetchRasPis(labId) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
    ApiConstants.VIEW_GROUP_LIST_URL + '/' +
    labId + '/' +
    ApiConstants.THINGS,
    withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(rasPis => {
      if (rasPis.hasOwnProperty('data')) {
        logToConsole('RasPis: ' + JSON.stringify(rasPis.data));
        return rasPis.data;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      networkError(error, fetchRasPis.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

export function getRasPiSetupCommand(distro, rasPiId, pluginsToInstall, pythonVersion = "3.5.3") {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    let queryParams = "?pythonVersion=" + pythonVersion + "&plugins=" + pluginsToInstall;
    return axios.get(controllerEndpoint +
      ApiConstants.VIEW_THING_SETUP.replace('thingId', rasPiId).replace('distro', distro) + queryParams,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(thingSetupCom => {
        if (thingSetupCom.hasOwnProperty('data')) {
          logToConsole('RasPi Setup Command: ' + thingSetupCom.data.cmdLine);
          return {
            data: thingSetupCom.data.cmdLine
          };
        } else {
          return {
            error: AppConstants.NOCMDLINE
          };
        }
      })
      .catch(error => {
        networkError(error, getRasPiSetupCommand.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
  }
}

/**
 * Invoke v2 update api to perform software update on ubuntu thing
 * @param labId
 * @param rasPiId
 * @param actionId
 * @returns {Promise<unknown>|Promise<AxiosResponse<any> | {error: *}>}
 */
export function actionUbuntu(labId, rasPiId, actionId) {
  // TODO: write wrappers to customize plugins as params to this request
  const updatePayload = {
    "packages": [
      {
        "name": "mdxirs",
        "version": "latest"
      },
      {
        "name": "mdx_plugin_aqt_automotive",
        "version": "latest"
      }
    ]
  }

  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    return axios.post(controllerEndpoint +
      ApiConstants.VIEW_GROUP_LIST_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      AppConstants.rasPiAction[actionId].action + '/' +
      'v2',
      updatePayload,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
          return {success: rasPiStatus.data};
        } else {
          return {error: AppConstants.SERVERERR}
        }
      })
      .catch(error => {
        networkError(error, actionRasPi.name);
        return {
          error: error.message
        };
      });
  }
}

export function actionRasPi(labId, rasPiId, actionId) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    return axios.get(controllerEndpoint +
      ApiConstants.VIEW_GROUP_LIST_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      AppConstants.rasPiAction[actionId].action,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
          return { success: rasPiStatus.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        networkError(error, actionRasPi.name);
        return {
          error: error.message
        };
      });
  }
}

export function actionRasPiWithBodyAndAckTimeout(labId, rasPiId, actionId, body, ackTimeout) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    const action = AppConstants.rasPiAction[actionId] ? AppConstants.rasPiAction[actionId].action : actionId;
    console.log(action);
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    return axios.post(controllerEndpoint +
      ApiConstants.VIEW_GROUP_LIST_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      action +
      '?ackTimeout=' + ackTimeout,
      body,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          logToConsole('RasPi status : ' + rasPiId + JSON.stringify(rasPiStatus.data));
          return { success: rasPiStatus.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        networkError(error, actionRasPiWithBodyAndAckTimeout.name);
        return {
          error: AppConstants.NETWORK_ERROR
        };
      });
  }
}

export function actionLab(labId, actionId) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!labId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NOLab
      })
    });
  } else {
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    return axios.get(controllerEndpoint +
      ApiConstants.VIEW_GROUP_LIST_URL + '/' +
      labId + '/' +
      AppConstants.rasPiAction[actionId].action,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
          return { success: rasPiStatus.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        networkError(error, actionRasPi.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
  }
}


export function getWhitelistedScenarios() {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
    ApiConstants.GET_WHITELISTED_SCENARIOS,
    withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      if (response.hasOwnProperty('data')) {
        logToConsole('Get whitelisted scenarios: ' + JSON.stringify(response.data));
        return { scenarios: response.data ? response.data : [] };
      } else {
        return { error: AppConstants.SERVERERR }
      }
    })
    .catch(error => {
      networkError(error, getWhitelistedScenarios.name);
      return { error: AppConstants.NETWORKERR };
    });
}

export function talkRasPi(labId, rasPiId, fileName, timeOut,
  rasPiControlName = AppConstants.RAS_PI_CONTROL_NAME) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    let body = {
      'waveFileName': fileName,
      'volume': 100,
      'controlName': rasPiControlName
    }
    return axios.post(controllerEndpoint +
      ApiConstants.VIEW_GROUP_LIST_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      AppConstants.rasPiAction.TALK.action +
      '?actionTimeout=' + timeOut,
      body,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          logToConsole('RasPi status : ' + JSON.stringify(rasPiStatus.data));
          return { success: rasPiStatus.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        networkError(error, talkRasPi.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
  }
}

/**
 * Gets the list of manifests for particular lab and location
 */
export function getManifestsForRasPi(labId, rasPiId, ackTimeout, actionTimeout) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!rasPiId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NORASPI
      })
    });
  } else {
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    return axios.post(controllerEndpoint +
      ApiConstants.VIEW_GROUP_LIST_URL + '/' +
      labId + '/' +
      ApiConstants.THINGS + '/' +
      rasPiId + '/' +
      SyncResourceConstants.ACTION_GET_MANIFESTS_STATE +
      '?ackTimeout=' + ackTimeout +
      '&actionTimeout=' + actionTimeout,
      SyncResourceConstants.GET_MANIFESTS_STATE_BODY,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(rasPiStatus => {
        if (rasPiStatus.hasOwnProperty('data')) {
          return { success: rasPiStatus.data };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        networkError(error, getManifestsForRasPi.name);
        return {
          error: AppConstants.NETWORK_ERROR
        };
      });
  }
}

/**
 * Performs a put into Labs table updating preference column for given lab.
 * @param labId id of Lab for which preference column is updated.
 * @param preferenceStr user preference json string of raspi location and name
 * @returns success -> {success: success code, data: response data}
 *          error -> {error: error code}
 */
export function saveLabUserPreference(labId, preferenceStr) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  if (!labId) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.NOLABS
      })
    });
  } else {
    let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
    let body = {
      'preference': preferenceStr
    }
    return axios.put(controllerEndpoint +
      ApiConstants.VIEW_GROUP_LIST_URL + '/' + labId,
      body,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
      .then(labUpdateResponse => {
        if (labUpdateResponse.hasOwnProperty('status')
          && labUpdateResponse.hasOwnProperty('data')
          && labUpdateResponse.status == 200) {
          return {
            success: labUpdateResponse.status,
            data: labUpdateResponse.data
          };
        } else {
          return { error: AppConstants.SERVERERR }
        }
      })
      .catch(error => {
        networkError(error, talkRasPi.name);
        return {
          error: AppConstants.NETWORKERR
        };
      });
  }
}

/**
 * send MQTT message to RasPi
 *
 * @param {string} companyId
 * @param {string} rasPiName
 * @param {object} payload
 */
export function sendMQTTMessage(companyId, rasPiName, payload, timeout = MQTT_TIMEOUT) {

  let uniqId = uuidv4();

  // Update old RaspiName which contains companyId
  if (rasPiName.indexOf(companyId) !== -1) {
    rasPiName = rasPiName.replace(companyId + '-', '');
  }

  let topic = companyId + "/" + companyId + "-" + rasPiName + "/" + uniqId;

  const mqttPayload = {
    requestTopic: topic + "/request",
    responseTopic: topic + "/response",
    payload,
  };

  logToConsole("Sending MQTT message: ", mqttPayload);

  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }

  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  return axios.post(controllerEndpoint +
    ApiConstants.SEND_MQTT_MESSAGE +
    '?waitForActionTime=' + timeout,
    mqttPayload,
    withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      logToConsole("Response from MQTT: ", response);
      return response;
    })
    .catch(error => {
      logToConsole("Error from MQTT: ", error);
      return error.response.data;
    });

}

/**
 * Perfroms controller api call send MQTT message
 * invoke plugin actions
 *
 * @param {string} companyId
 * @param {string} rasPiName
 * @param {string} payload
 */
export function commonOps(companyId, rasPiName, payload) {
  return sendMQTTMessage(companyId, rasPiName, payload)
    .then(response => {
      if (response.error) {
        let payload = convertStrToJson(response.error);
        // console.log('response :', payload);
        if (payload.state == 'failed') {
          return new Promise(resolve => { resolve(false) });
        }
      } else {
        try {
          const actualResponse = convertStrToJson(response.data);
          const actualPayload = convertStrToJson(actualResponse.payload);
          if (actualPayload.code == 200) {
            return new Promise(resolve => { resolve(true) });
          }
        } catch (err) {
          logToConsole('Exception caught: ', err)
        } finally {
          return new Promise(resolve => { resolve(false) });
        }
      }
    });
}

/**
 * Utility function to ping the raspi for
 * online/offline check
 *
 * @param {string} labId
 * @param {string} rasPiId
 */
export function pingRasPi(labId, rasPiId) {
  return actionRasPi(labId, rasPiId, 'STATE').then(response => {
    console.log(response.success.payload.thingStatus);
    let rasPiStatus = response.success.payload.thingStatus || RASPI_OFFLINE;
    return new Promise(resolve => {
      resolve(rasPiStatus)
    })
  })
    .catch(error => {
      console.log("Exception caught while fetching the ping status for ".rasPiId);
      return new Promise(resolve => {
        resolve(RASPI_OFFLINE)
      })
    });
}


/**
 * Create hotspot with the provided ssid and
 * passphrase
 *
 * @param {string} companyId
 * @param {string} rasPiName
 * @param {string} ssid
 * @param {string} passphrase
 * @param {string} band
 * @param {string} channel
 * @param {string} wifiInterface
 */
export function createHotspot(companyId, rasPiName, ssid, passphrase, band, channel, wifiInterface) {
  let payload = { ssid, passphrase };
  if(wifiInterface && band && channel){
    payload = { ssid, passphrase, interface: wifiInterface, band, channel};
  }
  let hotspotConfig = CREATE_HOTSPOT_PAYLOAD;
  hotspotConfig['payload'] = payload;
  return sendMQTTMessage(
    companyId, rasPiName, hotspotConfig)
    .then(response => {
      logToConsole('response from createHotspot request: ', response);
      if (!ld.isEmpty(response) && ld.has(response, 'data', null)) {
        const responseData = JSON.parse(response.data);
        if (ld.get(responseData, 'payload.code', null) === 200) {
          return true;
        }
      }
      return false;
    });
}

/**
 * Retrieve hotspot configs for the requested raspi
 *
 * @param {string} companyId
 * @param {string} rasPiName
 */
export function getHotspotConfigs(companyId, rasPiName) {
  return sendMQTTMessage(companyId, rasPiName, GET_HOTSPOT_CONFIG_PAYLOAD)
    .then(response => {
      if (response && response.data && response.data.length > 0) {
        if (response.data[0].search(PLUGIN_NOT_INSTALLED) > -1 || response.data[0].search(PLUGIN_NOT_CONFIGURED) > -1) {
          return {
            data: null,
            status: PLUGIN_NOT_INSTALLED
          }
        } else {
          return {
            data: convertStrToJson(response.data[0]),
            status: PLUGIN_INSTALLED
          }
        }
      } else {
        return {
          data: null,
          status: PLUGIN_ERROR
        }
      }
    });
}

/**
 * Fetches connected devices for the hotspot-enabled raspi
 * @param {string} companyId
 * @param {string} rasPiName
 */
export function getConnectedDevices(companyId, rasPiName) {
  return sendMQTTMessage(companyId, rasPiName, GET_CONNECTED_DEVICES_PAYLOAD)
    .then(response => {
      if (response.error && response.error.search(PLUGIN_NOT_INSTALLED) > -1) {
        return {
          data: null,
          status: PLUGIN_NOT_INSTALLED
        }
      } else if (!ld.isNil(response) && ld.has(response, 'data') && response.data.length > 0 && response.status === 200) {
        let data = convertStrToJson(response.data);
        let payload = convertStrToJson(data.payload);
        return {
          data: payload,
          status: PLUGIN_INSTALLED
        }
      } else {
        return {
          data: null,
          status: PLUGIN_ERROR
        }
      }
    });
}


/**
 * Performs AQT hotspot plugin installation on the raspi
 *
 * @param {string} labId
 * @param {string} rasPiId
 */
export function installHotspotPluginOnRasPi(labId, rasPiId) {
  return actionRasPiWithBodyAndAckTimeout(labId,
    rasPiId,
    ACTION_INSTALL_PLUGIN,
    PAYLOAD_INSTALL_PLUGIN,
    ACTION_TIMEOUT).then(response => {
      console.log(response);
      const status = response.success && response.success.state === 'complete' ? true : false;
      return new Promise(resolve => {
        resolve(status)
      });
    })
    .catch(error => {
      console.log("Exception caught while installing plugin");
      return new Promise(resolve => {
        resolve(0);
      })
    });
}


/**
 * Perfroms ping to raspi with retry mechanism
 *
 * @param {string} labId
 * @param {string} rasPiId
 * @param {int} retryCount
 */
export async function retryPing(labId, rasPiId, retryCount) {
  for (let i = 1; i <= retryCount; i++) {

    await sleep(10000 * i);
    console.log('retrying ping request -', i);
    const status = await pingRasPi(labId, rasPiId);
    switch (status) {
      case RASPI_ONLINE:
        return new Promise(resolve => { resolve(RASPI_ONLINE) });
      case RASPI_OFFLINE:
      default:
        break;
    }
  }
  return new Promise(resolve => { resolve(RASPI_OFFLINE) });
}

/**
 * Utility function to sleep asynchronously
 *
 * @param {int} time
 */
export function sleep(time) {
  logToConsole('sleep for', time, 'seconds');
  return new Promise((resolve) => setTimeout(resolve, time));
}

/**
 * Utility function to generate item field for calibration option in labs page
 *
 * @param {string} file name prefix
 */

export function generateItemFieldsForCalibration(filename, type) {
  let items = [];
  if (type == "SKILL") {
    ld.each(AppConstants.MARKETPLACE, (val) => {
      if (!["en_CA", "ja_JP"].includes(val.id)) {
        let item = {};
        item.id = val.id + filename;
        item.text = val.label;
        items.push(item);
      }
    });
    return items;
  } else if (type == "HAPPY") {
    ld.each(AppConstants.MARKETPLACE, (val) => {
      if (!["en_CA"].includes(val.id)) {
        let item = {};
        item.id = val.id + filename;
        item.text = val.label;
        items.push(item);
      }
    });
    return items;
  }
}

