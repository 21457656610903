import React, {useEffect, useState} from 'react';
import {
  Alert,
  Header,
  Select,
  ContentLayout,
  Button,
  FormField,
  SpaceBetween,
  Box,
  HelpPanel,
  Tiles,
  Input,
  Grid
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import { HexClient as client } from './client';
import { Device } from "./device";
import _ from 'lodash';
import { deviceInfo } from "./util";

function DeviceSelect({ onSelect }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [endpoint, setEndpoint] = React.useState(null);

  useEffect(() => loadDevices(), []);

  const loadDevices = async () => {
    setLoading(true);
    try {
      let resp = await client.deviceaccounts();
      let customerId = _.get(resp, "data.CustomerId", '');
      let devicesReg = _.get(resp, "data.RegisteredDevices", []);
      if (customerId && devicesReg) {
        let devices = [];
        devicesReg.map(item => {
          let device = new Device(customerId, item);
          devices.push(device);
        });
        setAccount({ customerId, devices });
      } else {
        throw Error('Device accounts info unavailable');
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    if (!selectedOption) {
      setSelectedOption(null);
    }
  }

  const build = (predicate, disabled) => {
    return _.map(_.filter(account.devices, predicate), device => {
      return {
        device: device,
        label: device.dsn,
        description: device.endpoint,
        tags: [device.type, device.name],
        disabled: disabled
      }
    })
  }

  const switchDevice = async () => {
    let url;
    try {
      url = _.trim(new URL(endpoint).toString(), '/');
    } catch (_) {
      window.alert("Not a valid URL. Missing http:// or https:// ?");
      return;
    }
    setDisabled(true);
    let switched = await selectedOption.device.switch(url);
    await loadDevices();
    setDisabled(false);
  }

  const options = loading ? [] : [
    {
      label: "Online",
      options: build(device => device.endpoint !== null, false)
    },
    {
      label: "Offline",
      options: build(device => device.endpoint === null, false)
    }
  ]

  const getEndpoints = (disabled = true) => {
    return [
      {
        value: "https://aqt-gateway.iad.amazon-aqt.com",
        label: "Alexa Prod (with OAK)",
        disabled: disabled
      },
      {
        value: "https://aqt-gateway.preprod.iad.amazon-aqt.com",
        label: "Alexa PreProd (with OAK)",
        disabled: disabled
      },
      {
        value: "https://aqt-gateway.gamma.iad.amazon-aqt.com",
        label: "Alexa Gamma (with OAK)",
        disabled: disabled
      },
      {
        value: "https://bob-dispatch-prod-na.amazon.com",
        label: "Alexa Prod",
        disabled: disabled
      },
      {
        value: "https://bob-dispatch-preprod-na.amazon.com",
        label: "Alexa PreProd",
        disabled: disabled
      },
      {
        value: "https://bob-dispatch-gamma-na.amazon.com",
        label: "Alexa Gamma",
        disabled: disabled
      }
    ]
  }

  return (
    <ContentLayout
      defaultPadding
      disableOverlap
      headerVariant="divider"
      header={
        <Header
          variant="h2"
          description="Switch endpoint of Alexa devices and use device Playground with OAK endpoints"
        >
          Devices
        </Header>
      }
    >
      <FormField
          secondaryControl={<Button iconName="refresh" onClick={loadDevices}/>}
      >
        <Select
            disabled={disabled}
            selectedOption={selectedOption}
            onChange={({detail}) => {
              setSelectedOption(detail.selectedOption);
            }}
            options={options}
            filteringType="auto"
            loadingText="Listing devices"
            placeholder="Choose a device"
            statusType={ loading ? "loading" : "finished" }
        />
      </FormField>
      { selectedOption && selectedOption.device && (
        <SpaceBetween size="xxs">
          <HelpPanel header={<Box textAlign="center" variant="h4"> Device Info </Box>}>
            { deviceInfo(selectedOption.device) }
          </HelpPanel>
          <HelpPanel header={<Box textAlign="center" variant="h4"> Switch Endpoint </Box>}>
            <SpaceBetween size="m">
              <FormField stretch>
                <Tiles
                    onChange={({ detail }) => setEndpoint(detail.value)}
                    value={endpoint}
                    items={getEndpoints(disabled)}
                />
              </FormField>
              <Grid
                gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}
              >
                <Input
                  disabled={disabled}
                  onChange={({ detail }) => setEndpoint(detail.value)}
                  value={endpoint}
                  autoComplete={false}
                  disableBrowserAutocorrect
                  inputMode="url"
                  type="url"
                />
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    loading={disabled}
                    disabled={!endpoint || !endpoint.length > 0}
                    onClick={switchDevice}>Switch
                  </Button>
                  <Button
                    disabled={disabled || !selectedOption.device.inOak()}
                    onClick={() => onSelect(selectedOption.device)}
                    variant="primary">Playground
                  </Button>
                </SpaceBetween>
              </Grid>
              <Alert statusIconAriaLabel="Info">Playground is available for any device connected to one of OAK endpoints.</Alert>
            </SpaceBetween>
          </HelpPanel>
        </SpaceBetween>
      )
      }
    </ContentLayout>
  )
}

export default React.memo(DeviceSelect);
