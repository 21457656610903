import React, {useState, useEffect} from 'react';
import { HexClient as client } from './client'
import * as ld from 'lodash';
import {actionTrace, actionTraceList, llmTrace, params} from "./util";
import { logToConsole } from "../../Util";
import {
    Textarea,
    ContentLayout,
    SpaceBetween,
    Box,
    Select,
    FormField,
    Button
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';

function Metadata({ item, device }) {
    const [activities, setActivities] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const [selectedOption, setSelectedOption] = React.useState(null);

    useEffect(() => {
        const setVal = async () => {
            if (item) {
                getActivity(item)
                  .then(activities => {
                    setActivities(activities);
                    let utteranceId = ld.chain(
                      ld.get(activities, "ActivityItems", {}))
                      .map(i => i[0].UtteranceId).value();
                    getMetadata(utteranceId, "ALEXA_LLM_TRACE")
                      .then(d => setMetadata(d));
                  });
            } else {
                setSelectedOption(null); // Needed to make sure dropdown selection resets on page close
                setActivities(null);
                setMetadata(null);
            }
        };
        setVal();
    }, [item]);

    const getActivity = async (item) => {
        if (item) {
            let param = params(device.dsn, device.type, {
                'starttime': item.starttime,
                'endtime': item.starttime + 7,
                'limit': 1
            });
            let activities = await client.activityitems(param, device.env());
            return activities.data;
        }
        return {};
    }

    const getMetadata = async (utteranceId, forType) => {
        if (forType) {
            let param = params(device.dsn, device.type, {
                contenttype: forType,
                approximateinjectiontime: item.starttime,
                datatype: "DATA",
                utteranceid: utteranceId
            });
            let bytedata;
            try {
                bytedata = await client.alexametadata(param);
            } catch (err) {
                logToConsole(err);
            }
            bytedata = ld.get(bytedata, "data.ColdplayByteData.payload");
            return !bytedata ? "cannot get data at this time, (got here too fast?) please close this and retry after few seconds" : JSON.parse(atob(bytedata));
        }
    }

    const options = actionTraceList(metadata);
    const content = selectedOption ?
      JSON.stringify(actionTrace(metadata, selectedOption.value), null, 2) :
      JSON.stringify(metadata || "loading, please wait...", null, 2);
    return (
      <ContentLayout
        defaultPadding
        disableOverlap>
          <SpaceBetween size="m" direction="vertical">
              { llmTrace(device, activities, metadata) }
              <SpaceBetween>
                  <Box variant="awsui-key-label">Action Filter</Box>
                  <FormField
                    secondaryControl={<Button disabled={!selectedOption} onClick={() => setSelectedOption(null)}> Reset </Button>}
                  >
                      <Select
                        selectedOption={selectedOption}
                        onChange={({ detail }) =>
                          setSelectedOption(detail.selectedOption)
                        }
                        options={options}
                        loadingText="Loading"
                        placeholder="Choose an option"
                        statusType={ options.length > 0 ? "finished" : "loading" }
                        filteringType="auto"
                      />
                  </FormField>
              </SpaceBetween>
              <Textarea
                value={content}
                rows={16}
                readOnly
              />
          </SpaceBetween>
      </ContentLayout>
    );
}

export default React.memo(Metadata);
