import { getReleaseNotes } from './controller';
import AppConstants from '../../_Constants/AppConstants';
import { logToConsole } from '../../Util';

/**
 * Method to retrireve Latest Release Notes from controller.
 */
export function getLatestReleaseNotes() {
    return Promise.resolve(getReleaseNotes(AppConstants.RELEASE_NOTES_LATEST_RECORD).then(response => {
        if (!response.hasOwnProperty('error')) {
            logToConsole('Release notes from controller = ' + JSON.stringify(response));
            // returning the first result of array
            return response.releaseNotes;
        } else {
            logToConsole('Error retrieving release notes from controller');
            return AppConstants.UNKNOWN_RELEASE_NOTES;
        }
    }));
}
