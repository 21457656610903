import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { Header, Table, SpaceBetween, Spinner, Grid, Box, SideNavigation, Icon, Modal, StatusIndicator }
  from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import copy from 'copy-to-clipboard';
import {
  fetchLabsAndRasPis,
  getRasPiSetupCommand,
  actionRasPi,
  actionRasPiWithBodyAndAckTimeout,
  talkRasPi,
  getWhitelistedScenarios,
  jobSockets,
  generateItemFieldsForCalibration,
  actionUbuntu,
  sendMQTTMessage
} from './controller';
import {
    OVERVIEW_COLUMNS,
    DISTROS
} from './TableConfig';
import AppConstants from '../../_Constants/AppConstants';
import SyncResourceConstants from '../../_Constants/SyncResourceConstants';
import {AUTOMOTIVE_PLUGIN_INSTALL, QUAL_RF_INSTALL_PLUGIN} from "../../_Constants/AQTHotspotConstants";
import { logToConsole, getLoadingMessage, networkError } from '../../Util';
import { LABCONTROL_DEFINITION } from './LabControlConfig';
import { buildUserPreferenceObject, getRasPiName, getRasPiLoc } from '../../Util/index';
import { getSetupCmd, getUbuntuSetupCmd } from "./SetupHelper";
import ld from "lodash";
import {DEFAULT_PLUGINS, HEALTH_CHECK, PYTHON_VERSION, STOP_MORGAN_NOISE} from "./constants";

const STOP = 'Stop';
const START = 'Speaker Calibration';

const SYNC_QUERY_TIMEOUT = 20000;

const CALIBRATE_SKILL_ITEM_LIST = generateItemFieldsForCalibration("_Administrative_alexa_play_task_two_from_skill.wav", "SKILL");
const CALIBRATE_HAPPY_ITEM_LIST = generateItemFieldsForCalibration("_Administrative_alexa_play_happy_pharrell_williams.wav", "HAPPY");

const styles = theme => ({
  root: {
    width: '100%',
    minWidth: 600
  },
  nav: {
    maxHeight: '85vh',
    overflowY: 'auto'
  },
  message: {
    fontSize: theme.typography.pxToRem(30),
    flexBasis: '100%',
    flexShrink: 0,
    marginTop: 100
  },
  command: {
    height: 250,
    width: 450,
    textAlign: 'center',
    overFlowX: 'auto'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -13,
    marginLeft: -13
  },
  wrapper: {
    position: 'relative'
  }
});

class Labs extends React.Component {

  state = {
    labEnv: 'General',
    labsAndRasPis: {
      loading: false,
      data: {},
      error: {
        isError: false,
        message: null
      }
    },
    rasPiCmd: {
      loading: false,
      raspId: null,
      command: null,
      error: {
        isError: false,
        message: null
      }
    },
    healthModalVisible: false,
    healthCheck: {},
    healthCheckCurr: AppConstants.EMPTY,
    anchorEl: null,
    copyingCmd: false,
    actionAllLabs: false,
    whitelistedScenarioIds: [],
    activeTabIdMap: {},
    expandedLab: AppConstants.EMPTY,
    elapsedSeconds: 0,
    selectedRaspiItems: {}
  };

  piThingNameMap = {};

  componentWillMount() {
    this.getLabsAndRasPis();
    this.getWhitelistedScenarios();
  }

  componentWillUnmount() {
    Object.keys(jobSockets).forEach(function (socketId) {jobSockets[socketId].close()});
    clearInterval(this.interval);
  }

  componentDidMount() {
    if(this.state.elapsedSeconds <= SyncResourceConstants.MAX_WAIT_TIME) {
      this.interval = setInterval(() => this.setState({ elapsedSeconds: this.state.elapsedSeconds + 1 }), 1000);
    }
  }

  getLabsAndRasPis = () => {
    let cacheName = AppConstants.cache.LABSPIS;
    this.setState({ labsAndRasPis: {
        ...this.state.labsAndRasPis,
        loading: true
      }
    });
    let labsAndRasPisData = {};
    //TODO: Disabling Session storage with respect to multiple account usage.
    const cachedHits = sessionStorage.removeItem(cacheName);
    if (cachedHits) {
      logToConsole('Retrieved from Cache: ', cacheName);
      labsAndRasPisData = JSON.parse(cachedHits);
      this.setState({
        labsAndRasPis: {
          loading: false,
          data: labsAndRasPisData,
          error: { isError: false, message: null },
      }});
      this.runActionSequenceAllLabs(labsAndRasPisData, AppConstants.rasPiAction.STATE.id);
    } else {
      fetchLabsAndRasPis().then(labsAndRasPisPromises => {
        if (!labsAndRasPisPromises.hasOwnProperty('error')) {
          Promise.all(labsAndRasPisPromises)
            .then(labsAndThings => {
              logToConsole("Fetching labs");
              labsAndThings.forEach(labAndThings => {
                // Assign lab data
                labsAndRasPisData[labAndThings.lab.id] = labAndThings;
                labsAndRasPisData[labAndThings.lab.id].actionAllPis = false;
                labsAndRasPisData[labAndThings.lab.id].preference = buildUserPreferenceObject(labAndThings.lab, labsAndRasPisData[labAndThings.lab.id].rasPis);
                labsAndRasPisData[labAndThings.lab.id].error = { isError: false, message: null };
                logToConsole("lab id is " + labAndThings.lab.id);
                Object.keys(labsAndRasPisData[labAndThings.lab.id].rasPis).forEach(rasPi => {
                  let labIdAndPiIndexInfo = {};
                  labIdAndPiIndexInfo.labId = labAndThings.lab.id;
                  labIdAndPiIndexInfo.piIndex = rasPi;
                  this.piThingNameMap[labsAndRasPisData[labAndThings.lab.id].rasPis[rasPi].thingName] = labIdAndPiIndexInfo;
                  labsAndRasPisData[labAndThings.lab.id].rasPis[rasPi].rasPiStatus = {
                    [AppConstants.rasPiAction.STATE.action]: false,
                    [AppConstants.rasPiAction.UPDATE.action]: false,
                    [AppConstants.rasPiAction.STOP.action]: false,
                    status: false,
                    calibrate: false,
                    calibrationInProgress: false,
                  }
                });
              });
            })
            .then(() => {
              this.setState({
                labsAndRasPis: {
                  loading: false,
                  data: labsAndRasPisData,
                  error: { isError: false, message: null }
              }});
              //sessionStorage.setItem(cacheName, JSON.stringify(labsAndRasPisData));
              this.runActionSequenceAllLabs(labsAndRasPisData, AppConstants.rasPiAction.STATE.id);
            })
            .catch(error => {
              logToConsole('Error loading labs : ' + error);
              this.setState({
                labsAndRasPis: {
                  loading: false,
                  data: {},
                  error: { isError: true, message: 'Error loading labs and rasPis' }
              }});
              networkError(error, this.getLabsAndRasPis.name);
            });
        } else {
          this.setState({
            labsAndRasPis: {
              loading: false,
              data: {},
              error: { isError: true, message: labsAndRasPisPromises.error }
          }});
          sessionStorage.removeItem(cacheName);
        }
      });
    }
  }

  getRasPiSetup = (target, distro, rasPiId, labId) => {
    this.setState({ rasPiCmd: {
      ...this.state.rasPiCmd,
      raspId: rasPiId,
      loading: true
    }});
    let pluginsToInstall = [...DEFAULT_PLUGINS];
    if (this.isLocationUbuntuMachine(rasPiId, labId)) {
      pluginsToInstall.push(AUTOMOTIVE_PLUGIN_INSTALL.plugins);
    }
    if (this.isLocationQualRfMachine(rasPiId, labId)) {
      pluginsToInstall = [QUAL_RF_INSTALL_PLUGIN.plugins];
    }
    getRasPiSetupCommand(distro, rasPiId, pluginsToInstall, PYTHON_VERSION).then(rasPiCmd => {
      if (!rasPiCmd.hasOwnProperty('error')) {
        this.setState({
          anchorEl: target,
          rasPiCmd: {
            raspId: rasPiId,
            loading: false,
            command: this.isLocationUbuntuMachine(rasPiId, labId)
              ? getUbuntuSetupCmd(rasPiCmd.data) : rasPiCmd.data,
            error: { isError: false, message: null },
            popoutSize: 15
        }});
      } else {
        this.setState({
          anchorEl: target,
          rasPiCmd: {
            raspId: rasPiId,
            loading: false,
            command: null,
            error: { isError: true, message: rasPiCmd.error },
            popoutSize: 15
        }});
      }
    });
  }

  /**
   * Renders a Button component for thing Setup [Raspi]
   * @param {*} cmd the Setup command retrieved from the thing setup API call to be copied to the clipboard
   * @param {*} label the label for the Button
   * @param {*} variant the type of the Button [https://material-ui.com/api/button/]
   */
  getRasCmdButton = (cmd, label, variant) => {
    return (
      <Button color='primary' size='small' variant={ variant }
        disabled={ this.state.copyingCmd }
        onClick={ () => {
          this.setState({ copyingCmd: true });
          copy(cmd);
          this.setState({ copyingCmd: false, anchorEl: null });
        }}
      >
        { label }
      </Button>
    );
  }

  getRasCmdPop = () => {
    return (
      <Popover
        open={ Boolean(this.state.anchorEl) }
        anchorEl={ this.state.anchorEl }
        onClose={ () => {
          this.setState({ anchorEl: null });
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card className={ this.props.classes.command }>
          <CardContent>
            { !this.state.rasPiCmd.error.isError ? (
              <div>
                <TextField
                  multiline
                  fullWidth
                  disabled
                  rows={ this.state.rasPiCmd.popoutSize }
                  value={ this.state.rasPiCmd.command }
                >
                </TextField>
                <div className={ this.props.classes.wrapper }>
                  { this.getRasCmdButton(this.state.rasPiCmd.command, 'Copy to Clipboard', 'raised') }
                  { this.state.copyingCmd && <CircularProgress size={ 24 } className={ this.props.classes.buttonProgress } /> }
                </div>
              </div>
            ) : (
              <Typography className={ this.props.classes.message } variant='caption' color='error'>
                { this.state.rasPiCmd.error.message }
              </Typography>
            )}
          </CardContent>
        </Card>
      </Popover>
    );
  }

  /**
   * Utility to Check if the location name is Ubuntu
   * @param rasPiId - rasPi Index
   * @param labId - lab Index
   * @return boolean status if the location is Automotive Companion or not
   */
  isLocationUbuntuMachine(rasPiId, labId) {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    let isUbuntuLocation = false;
    if (currLabsAndRaspi.hasOwnProperty(labId)
        && currLabsAndRaspi[labId].preference
        && currLabsAndRaspi[labId].preference.actor_mapping_preference
        && currLabsAndRaspi[labId].preference.actor_mapping_preference[rasPiId]
        && currLabsAndRaspi[labId].preference.actor_mapping_preference[rasPiId].location) {
          if (currLabsAndRaspi[labId].preference.actor_mapping_preference[rasPiId].location.includes(AppConstants.AUTOMOTIVE_LOCATION)) {
            isUbuntuLocation = true;
          }
      }
    return isUbuntuLocation;
  }

  /**
   * Utility to Check if the location name is Qual and RF Companion
   * @param rasPiId - rasPi Index
   * @param labId - lab Index
   * @return boolean status if the location is Qual and RF Companion or not
   */
  isLocationQualRfMachine(rasPiId, labId) {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    let isRfLocation = false;
    if (currLabsAndRaspi.hasOwnProperty(labId)
        && ((currLabsAndRaspi[labId].preference || {}).actor_mapping_preference[rasPiId] || {}).location) {
      if (currLabsAndRaspi[labId].preference.actor_mapping_preference[rasPiId].location.includes(AppConstants.QUAL_RF_LOCATION)) {
        isRfLocation = true;
      }
    }
    return isRfLocation;
  }

  /**
   * Returns True if lab is configured for Automotive Test that uses Ubuntu Machine, False otherwise.
   * If raspi is named ubuntu then it is configured for Automotive Scenarios.
   */
  isUbuntuLab = (labId) => {
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    let isUbuntuLab = false;

    if (currLabsAndRaspi.hasOwnProperty(labId)
        && currLabsAndRaspi[labId].preference
        && currLabsAndRaspi[labId].preference.actor_mapping_preference) {

        var mapPref = currLabsAndRaspi[labId].preference.actor_mapping_preference;
        for (var raspiPref in mapPref) {
          var raspiData = mapPref[raspiPref];
          if (raspiData.location &&
            raspiData.location.includes(AppConstants.AUTOMOTIVE_LOCATION)) {
              isUbuntuLab = true;
            }
          }
        }

    return isUbuntuLab;
  }

  /**
   * Method to retrieve whitelisted scenarios for the account
   * @return List of whitelisted scenarios for the account
   */
  getWhitelistedScenarios = () => {
    let whitelistedScenariosIn = [];
    return Promise.resolve(getWhitelistedScenarios().then(response => {
      if (!response.hasOwnProperty('error')) {
        response.scenarios.forEach(whitelistedScenario => {
        if (AppConstants.WHITELISTED_SCENARIO_TYPES.includes(whitelistedScenario.name)
              && !whitelistedScenariosIn.includes(whitelistedScenario.name)) {
          whitelistedScenariosIn.push(whitelistedScenario.name);
         }
       });
      }
     this.setState({
      whitelistedScenarioIds: whitelistedScenariosIn
     });
    }));
  }

  /* Provides the Action buttons on the Rapsi table header */
  getRaspiActions = (labDetails, labName, size, labId, labsData) => {
    return (
        <Header
            counter={ "(" + size + ")" }
            actions={
              <SpaceBetween direction="horizontal" size="xxl">
                { this.getSetupAndCalibrate(labDetails) }
                { this.getLabControls(labId, labsData) }
              </SpaceBetween>
            }
        >
          { labName }
        </Header>
    );
  }

  /**
   * Gets the content for Overview tab in table format
   */
  getRasPiTableOverview = (labName, labId, data) => {
    let overviewTable = [];
    data.rasPis.forEach((rasPiData, index) => {

      overviewTable.push({
        labId: labId,
        rasPiIndex: index,
        id: rasPiData.id,
        name: getRasPiName(rasPiData, data),
        backName: rasPiData.name,
        companyId: data.lab.companyId,
        location: getRasPiLoc(rasPiData, index, AppConstants.defaultLocation, data),
        getPingStatus: this.getPingStatus,
        getSetupAndCalibrate: this.getSetupAndCalibrate
      });
    })

    return (
        <Table
            columnDefinitions={ OVERVIEW_COLUMNS }
            selectedItems={ this.state.selectedRaspiItems[labId] || [] }
            onSelectionChange={ ({detail}) =>
                this.setState({
                  selectedRaspiItems: {...this.state.selectedRaspiItems, [labId]: [detail.selectedItems[0]]}
                })
            }
            items={ overviewTable }
            selectionType="single"
            trackBy="id"
            header={
              this.getRaspiActions(this.state.selectedRaspiItems[labId], labName, overviewTable.length, labId, data)
            }
        />
    );
  }

  /**
   * Gets lab name for this lab ID
   */
  getLabName = (labId, data) => {
    if (labId && data && data.hasOwnProperty(labId)) {
      let lab = data[labId].lab;
      if (lab) {
        return lab.name;
      }
    }
    return AppConstants.EMPTY;
  }

  /**
   * Gets lab data for this lab ID
   */
  getLabData = (labId, data) => {
    if (labId && data && data.hasOwnProperty(labId)) {
      return data[labId];
    }
    return {};
  }

  /**
   * Gets all Raspi and labs data for the side navigation
   */
  getRasPiRecords = (data) => {
    let rasPiRecords = [];
    Object.keys(data).forEach(labId => {
          let labName = this.getLabName(labId, data);
          rasPiRecords.push({
            type: 'link',
            href: labId,
            text: <p><Icon name="caret-right-filled" /><strong> { labName } </strong></p>
          });
        });
    return rasPiRecords;
  }

  /**
   * Gets tabs to display for expanded lab
   */
  getTabsForLab = (labId) => {
    return ([{
        'label': 'Overview',
        'id': labId + '_overview_tab',
      }]);
  }

  /**
   * Gets active tab for current expanded lab
   */
  getActiveTabForLab = (labId) => {
    let activeTabId = AppConstants.EMPTY;
    if (labId) {
      // Look up for current active tab for this lab in the map
      if (this.state.activeTabIdMap.hasOwnProperty(labId)) {
        activeTabId = this.state.activeTabIdMap[labId];
      } else {
        // Set overview tab by default
        activeTabId = labId + '_overview_tab';
      }
    }
    return activeTabId;
  }

  /**
   * Gets section which represents a lab
   */
  getSectionForLab = (labId, labsData) =>  {
    let labName = this.getLabName(labId, labsData);
    let labData = this.getLabData(labId, labsData)
    let tabs = this.getTabsForLab(labId);

    // If no tabs information was found, don't render anything
    if (!tabs || tabs.length == 0) {
      return (
        <div></div>
      )
    }
    let activeTabId = this.getActiveTabForLab(labId);
    return (
      <div>
        {
          labId && labData && Object.keys(labData).length > 0 ? (
            <div>
              <AWSUI.Tabs
                tabs={ tabs }
                variant="container"
                activeTabId={ activeTabId }
                onChange={ event => {
                this.setState({
                  activeTabIdMap: {
                    ...this.state.activeTabIdMap,
                    [labId]: event.detail.activeTabId
                    }
                  })
                }}
              />
              {
                activeTabId.includes('overview_tab') && (
                this.getRasPiTableOverview(labName, labId, labData)
                )
              }
            </div>
          ) : (
            <div align='center'>
              {
                getLoadingMessage(SyncResourceConstants.ERROR_NO_DATA)
              }
            </div>
          )
        }
      </div>
    );
  }

  /**
   * Gets the setup and calibration buttons for rasPi
   */
  getSetupAndCalibrate = (labDetails) => {
    return (
      <span align='center'>
        <span className='awsui-util-ml-xs'>
        {
          labDetails ?
              this.getSetupCell(labDetails[0].labId, labDetails[0].id) :
              <AWSUI.Button variant='primary' text='Setup' iconAlign='right' icon='caret-down-filled' disabled={ true } />
        }
        </span>
        <span className='awsui-util-ml-xs'>
        {
          labDetails ?
              this.getHealthCell(labDetails[0].companyId, labDetails[0].backName) :
              <AWSUI.Button variant='primary' text='Health Check' disabled={ true } />
        }
        </span>
      </span>
    )
  }

  getSetupCell = (labId, rasPiId) => {
    return (
      <span>
        <span className={ this.props.classes.wrapper }>
          <AWSUI.ButtonDropdown
              variant="primary"
              disabled={ this.state.rasPiCmd.raspId === rasPiId ? this.state.rasPiCmd.loading : false }
              items={ DISTROS }
              onItemClick={ (event) => {
                this.getRasPiSetup(event.currentTarget, event.detail.id, rasPiId, labId);
              }}
          >
            Setup
          </AWSUI.ButtonDropdown>
        </span>
        { this.getRasCmdPop() }
      </span>
    );
  }

  getHealthCheckStep = (step, name) => {
    return (
      <Box fontSize="heading-s" variant="code"> {step}
        <Box float="right">
          <StatusIndicator type={this.state.healthCheck[name] || "loading"}> {this.state.healthCheck[name] || "loading"} </StatusIndicator>
        </Box>
      </Box>
    );
  }

  setHealthState(name, state) {
    this.setState({...this.state, healthCheck: {...this.state.healthCheck, [name]: state}});
  }

  runHealthCheckStep = (companyId, raspiName) => {
    this.setState({
      ...this.state,
      healthModalVisible: true,
      healthCheckCurr: raspiName
    });
    HEALTH_CHECK.map(check => {
      return new Promise(resolve => {
        sendMQTTMessage(companyId, raspiName, check.payload, check.wait)
          .then(response => {
            if (this.state.healthCheckCurr == raspiName) {
              if (ld.get(response, 'data[0]', '').includes('complete')) {
                this.setHealthState(check.name,"success");
              } else {
                this.setHealthState(check.name,"error");
              }
            }
            resolve();
          })
      })
    });
  }

  getHealthCell = (companyId, raspiName) => {
    return (
      <span>
        <Modal
          onDismiss={() => {
            this.setState({
              ...this.state,
              healthModalVisible: false,
              healthCheck: {}
            });
          }}
          visible={this.state.healthModalVisible}
          header="Health Check"
        >
          <SpaceBetween direction="vertical" size="xxl">
            { HEALTH_CHECK.map(check => {
              return this.getHealthCheckStep(check.desc, check.name)
            }) }
          </SpaceBetween>
        </Modal>
        <AWSUI.Button variant='primary' text='Health Check'
                      onClick={() => { this.runHealthCheckStep(companyId, raspiName) }}/>
      </span>
    );
  }

  getCalibrationButton = (labId, rasPiIndex, id) => {
    let currRaspiData = Object.assign({}, this.state.labsAndRasPis.data);
    let rasPiStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus;
    let rasPiId = currRaspiData[labId].rasPis[rasPiIndex].id;
    let calibrateStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus.calibrate;
    let calibrateText = calibrateStatus ? STOP : START;

    const speakerCalibration = {
        "id": AppConstants.PINK_NOISE_FILE,
        "text": calibrateText
      };

    const skill = {
        "id": "DUT_SKILL",
        "text": "DUT Calibration - Skill",
        "disabled": calibrateStatus,
        "items": CALIBRATE_SKILL_ITEM_LIST
      };

    const happySong = {
      "id": "DUT_HAPPY",
      "text": "DUT Calibration - Happy Song",
      "disabled": calibrateStatus,
      "items": CALIBRATE_HAPPY_ITEM_LIST
    };

    let items = this.isUbuntuLab(labId) ? [skill, happySong] : [speakerCalibration, skill, happySong];

    if (rasPiStatus) {
      return (
        <AWSUI.ButtonDropdown items={items} expandableGroups={true}
          disabled={ currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus.calibrationInProgress }
          onItemClick={ (event) => {
            if (!calibrateStatus) {
              this.runTalkRasPi(labId, currRaspiData, rasPiIndex, id, rasPiId, calibrateStatus, event.detail.id);
            } else {
              this.runStopRasPi(labId, currRaspiData, rasPiIndex, AppConstants.rasPiAction.STOP.id, rasPiId);
            }
          }}
        >
          Calibrate
        </AWSUI.ButtonDropdown>
      )
    } else {
      return (
        <AWSUI.Icon name='status-warning'></AWSUI.Icon>
      )
    }
  }

  /**
   * Private utility to show Lab Calibration tool button on the Labs page for Ubuntu Machine.
   */
  getUbuntuLabCalibrationButton = () => {
    return (
      <span>
        <span className={ this.props.classes.wrapper }>
        <AWSUI.Button text='Lab Calibration Tool'
          onClick={ (event) => {
            this.setState({
              anchorEl: event.currentTarget,
              rasPiCmd: {
                loading: false,
                command: AppConstants.MORGAN_UBUNTU_LAB_CALIBRATION_TOOL_CMD,
                error: { isError: false, message: null },
                popoutSize: 3
            }});
          }}
        />
        </span>
        { this.getRasCmdPop() }
      </span>
    );
  }

  /**
   * Gets controls common across all locations such as Ping all pis, stop audio, update software for all
   * and update resources for all
   */
  getLabControls = (labId, data) => {
    return (
      <AWSUI.ButtonDropdown items={ LABCONTROL_DEFINITION } text='Lab Controls'
        loading={ this.state.actionAllLabs || this.state.labsAndRasPis.data[labId].actionAllPis }
          onItemClick={ (event) => {
            this.runActionSequence(data, event.detail.id);
          }}
        >
      </AWSUI.ButtonDropdown>
    );
  }

  getPingStatus = (labId, rasPiIndex) => {
    let currRaspiData = Object.assign({}, this.state.labsAndRasPis.data);
    let rasPiStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus;
    if (rasPiStatus) {
      return (
        <div>
          { rasPiStatus.status ? (
            <div className={ rasPiStatus.status === AppConstants.ONLINE ? 'awsui-util-status-positive' : 'awsui-util-status-negative' }>
              <AWSUI.Icon name={ rasPiStatus.status === AppConstants.ONLINE ? 'status-positive' : 'status-negative' } />
              <span className='dark-grey-color-text awsui-util-ml-xs'>
                { rasPiStatus.status }
              </span>
            </div>
          ) : (
            <span>
              <AWSUI.Spinner />
            </span>
          )}
        </div>
      )
    } else {
      return (
        <AWSUI.Icon name='status-warning'></AWSUI.Icon>
      )
    }
  }

  runActionRasPi = (labId, currRaspiData, rasPiIndex, actionId) => {
    currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
      ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
      status: false,
      [AppConstants.rasPiAction[actionId].action]: true
    };
    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currRaspiData
      }
    });
    return actionRasPi(labId, currRaspiData[labId].rasPis[rasPiIndex].id, actionId).then(response => {
      let status = AppConstants.OFFLINE;
      if (response.hasOwnProperty("success")
          && response.success.hasOwnProperty("payload")
          && response.success.payload.hasOwnProperty("thingStatus")
          && response.success.payload.thingStatus.toUpperCase().includes(AppConstants.ONLINE.trim().toUpperCase())) {
            status = AppConstants.ONLINE;
      } else if (response.hasOwnProperty("error")) {
        status = AppConstants.STATUS_ERROR;
      }
      currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
        ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
        status: status,
        [AppConstants.rasPiAction[actionId].action]: false
      };
      this.setState({
        labsAndRasPis: {
          ...this.state.labsAndRasPis,
         data: currRaspiData
        }
      });
      return;
    });
  }

  /**
   * Automotive Lab uses only one ubuntu for running tests
   * Hence we use v2 update api to perform software update
   * by passing specific mdx plugins
   * @param labId
   * @param ubuntuId
   * @param actionId
   * @param currRaspiData
   * @returns {Promise<T>}
   */
  performAutomotiveUbuntuActions = (labId, ubuntuThingIndex, actionId, currRaspiData) => {
    const ubuntuThingId = currRaspiData[labId].rasPis[ubuntuThingIndex].id;
    currRaspiData[labId].rasPis[ubuntuThingIndex].rasPiStatus = {
      ...currRaspiData[labId].rasPis[ubuntuThingIndex].rasPiStatus,
      status: false,
      [AppConstants.rasPiAction[actionId].action]: true
    };
    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currRaspiData
      }
    });
    return actionUbuntu(labId, ubuntuThingId, actionId).then(response => {
      let status = AppConstants.OFFLINE;
      if (response.hasOwnProperty("success")
        && response.success.hasOwnProperty("payload")
        && response.success.payload.hasOwnProperty("thingStatus")
        && response.success.payload.thingStatus.toUpperCase().includes(AppConstants.ONLINE.trim().toUpperCase())) {
        status = AppConstants.ONLINE;
      } else if (response.hasOwnProperty("error")) {
        status = AppConstants.STATUS_ERROR;
      }
      currRaspiData[labId].rasPis[ubuntuThingIndex].rasPiStatus = {
        ...currRaspiData[labId].rasPis[ubuntuThingIndex].rasPiStatus,
        status: status,
        [AppConstants.rasPiAction[actionId].action]: false
      };
      this.setState({
        labsAndRasPis: {
          ...this.state.labsAndRasPis,
          data: currRaspiData
        }
      });
      return;
    });
  }

  runActionRasPiWithReturn = (labId, currRaspiData, rasPiIndex, actionId, body) => {
    currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
      ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
      status: false,
      [AppConstants.rasPiAction[actionId].action]: true
    };
    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currRaspiData
      }
    });
    return actionRasPiWithBodyAndAckTimeout(labId, currRaspiData[labId].rasPis[rasPiIndex].id, actionId, body, SYNC_QUERY_TIMEOUT).then(response => {
      if (!response.hasOwnProperty('error')) {
        currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
          ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
          status: response.success ? AppConstants.ONLINE : AppConstants.OFFLINE,
          [AppConstants.rasPiAction[actionId].action]: false
        };
        this.setState({
          labsAndRasPis: {
            ...this.state.labsAndRasPis,
           data: currRaspiData
          }
        });
      } else {
        currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
          ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
          [AppConstants.rasPiAction[actionId].action]: false
        };
        this.setState({
          labsAndRasPis: {
            ...this.state.labsAndRasPis,
           status: false,
           data: currRaspiData
          }
        });
      }
      if (response.hasOwnProperty('success')) {
        return response.success;
      }
      if (response.hasOwnProperty('error')) {
        logToConsole("Error content: " +  response.error);
        return response;
      }
      return;
    });
  }

  runStopRasPi = (labId, currRaspiData, rasPiIndex, actionId, rasPiId) => {
    return actionRasPi(labId, currRaspiData[labId].rasPis[rasPiIndex].id, actionId).then(response => {
      if (!response.hasOwnProperty('error')) {
        logToConsole('SUCCESS runStopRasPi: ', rasPiId);
        currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
          ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
          calibrate: false
        };

        for (var rasPiIndexIn = 0; rasPiIndexIn < currRaspiData[labId].rasPis.length; rasPiIndexIn++) {
          currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus = {
            ...currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus,
           calibrationInProgress : false,
          };
        }

        this.setState({
          labsAndRasPis: {
            ...this.state.labsAndRasPis,
           data: currRaspiData
          }
        });
      } else {
        logToConsole('ERROR runStopRasPi: ', rasPiId);
      }
      return;
    });
  }

  runTalkRasPi = (labId, currRaspiData, rasPiIndex, actionId, rasPid, calibrateStatus, talkFileName) => {
    const { PINK_NOISE_FILE_TIMEOUT } = AppConstants;
    let newCalibrateStatus = calibrateStatus ? false : true;
    let rasPiName = currRaspiData[labId].rasPis[rasPiIndex].name;
    let controlName = AppConstants.RAS_PI_CONTROL_NAME;
    const hatsPiNameSuffix = "WITHDAC";
    if (rasPiName.toUpperCase().includes(hatsPiNameSuffix)) {
       controlName = AppConstants.HIFI_PI_CONTROL_NAME;
    }
    currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
      ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
      calibrate: newCalibrateStatus,
    };

    for (var rasPiIndexIn = 0; rasPiIndexIn < currRaspiData[labId].rasPis.length; rasPiIndexIn++) {
      if (rasPiIndexIn !== rasPiIndex) {
        currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus = {
          ...currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus,
          calibrationInProgress : true,
        };
      }
    }

    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currRaspiData
      }
    });

    return talkRasPi(labId, rasPid, talkFileName, PINK_NOISE_FILE_TIMEOUT, controlName).then(response => {
      if (!response.hasOwnProperty('error')) {
        logToConsole('SUCCESS runTalkRasPi: ', rasPid);
        this.resetCalibrationState(labId, currRaspiData, rasPiIndex);
        return;
      } else {
        logToConsole('ERROR runTalkRasPi: ', rasPid);
        this.resetCalibrationState(labId, currRaspiData, rasPiIndex);
        return;
      }
    });
  }

  runSyncRasPiWithPushMechanism = (labId, currRaspiData, rasPiIndex, downloadResourceJson, actionId) => {
    let body = downloadResourceJson;
    let rasPiName = currRaspiData[labId].rasPis[rasPiIndex].name;
    logToConsole("Current raspi for sync: " + rasPiName);

    let downloadStatus = currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus.downloadStatus;
    if (downloadStatus === AppConstants.DOWNLOAD_STATUS.INITIAL) {
      // display initial state immediately after user click sync button
      // haven't started, then Starting
      currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus.downloadStatus = AppConstants.DOWNLOAD_STATUS.START;
      this.setState({
        labsAndRasPis: {
          ...this.state.labsAndRasPis,
          data: currRaspiData
        }
      });
    }
    this.runActionRasPiWithReturn(labId, currRaspiData, rasPiIndex, actionId, body);
  }

  resetCalibrationState = (labId, currRaspiData, rasPiIndex) => {
    currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus = {
      ...currRaspiData[labId].rasPis[rasPiIndex].rasPiStatus,
      calibrate: false,
    };

    for (var rasPiIndexIn = 0; rasPiIndexIn < currRaspiData[labId].rasPis.length; rasPiIndexIn++) {
      currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus = {
        ...currRaspiData[labId].rasPis[rasPiIndexIn].rasPiStatus,
        calibrationInProgress : false,
      };
    }

    this.setState({
    labsAndRasPis: {
      ...this.state.labsAndRasPis,
      data: currRaspiData
    }
   });
  }

  runActionSequenceAllLabs = (labsAndRasPisData, actionId) => {
    let allActionPromises = [];
    this.setState({ actionAllLabs: true });
    Object.keys(labsAndRasPisData).forEach(labId => {
      labsAndRasPisData[labId].rasPis.forEach((rasPiData, index) => {
        allActionPromises.push(this.runActionRasPi(labId, labsAndRasPisData, index, actionId));
      });
    });
    Promise.all(allActionPromises).then(() => {
      this.setState({ actionAllLabs: false });
    });
  }

  /**
   * Find the Automotive Companion thing index for the current lab's things
   * using actor mapping preference
   * @param labThings
   * @returns {string|null}
   */
  findAutomotiveCompanionThingIndex = (labThings) => {
    const actor_mapping_preference = ld.get(labThings, 'preference.actor_mapping_preference');
    const rasPis = ld.get(labThings, 'rasPis');

    let rasPiKey;
    for (var key in actor_mapping_preference){
      const location = actor_mapping_preference[key].location;
      if (location==='Automotive Companion') {
        rasPiKey = key;
        break;
      }
    }

    for(var rasPi in rasPis) {
      const rasPiId = rasPis[rasPi].id;
      if (rasPiKey === rasPiId) {
        return rasPi;
      }
    }
    return null;
  }

  runActionSequence = (labAndRasPis, actionId) => {
    let allActionPromises = [];
    let currLabsAndRaspi = this.state.labsAndRasPis.data;
    currLabsAndRaspi[labAndRasPis.lab.id].actionAllPis = true;
    const isUbuntuLab = this.isUbuntuLab(labAndRasPis.lab.id);
    this.setState({
      labsAndRasPis: {
        ...this.state.labsAndRasPis,
        data: currLabsAndRaspi
      }
    });
    if (isUbuntuLab === true) {
      const ubuntuThingIndex = this.findAutomotiveCompanionThingIndex(currLabsAndRaspi[labAndRasPis.lab.id]);
      if (actionId === AppConstants.rasPiAction.UPDATE.id) {
        allActionPromises.push(this.performAutomotiveUbuntuActions(labAndRasPis.lab.id, ubuntuThingIndex, actionId, currLabsAndRaspi));
      } else if (actionId === AppConstants.rasPiAction.STOP.id) {
        const companyId = ld.get(currLabsAndRaspi[labAndRasPis.lab.id], 'lab.companyId');
        const rasPiName = ld.get(currLabsAndRaspi[labAndRasPis.lab.id].rasPis[ubuntuThingIndex], 'thingName').replace(companyId + '-', '');
        allActionPromises.push(sendMQTTMessage(companyId, rasPiName, STOP_MORGAN_NOISE));
      }
    } else {
      Object.keys(labAndRasPis.rasPis).forEach((rasPiData, index) => {
        logToConsole("actionId " + actionId);
        if (actionId === AppConstants.rasPiAction.SYNC.id) {
          logToConsole("Entering into Sync");
          allActionPromises.push(this.runSyncRasPiWithPushMechanism(labAndRasPis.lab.id, currLabsAndRaspi, index, actionId));
        } else if (actionId === AppConstants.rasPiAction.UPDATE.id && isUbuntuLab === true) {
          allActionPromises.push();
        } else {
          logToConsole("Other options");
          allActionPromises.push(this.runActionRasPi(labAndRasPis.lab.id, currLabsAndRaspi, index, actionId));
        }

      });
    }
    Promise.all(allActionPromises).then(() => {
      let currLabsAndRaspi = this.state.labsAndRasPis.data;
      currLabsAndRaspi[labAndRasPis.lab.id].actionAllPis = false;
      this.setState({
        labsAndRasPis: {
          ...this.state.labsAndRasPis,
          data: currLabsAndRaspi
        }
      });
    });
  }

  render() {
    // Don't auto refresh component for fetching data once MAX_WAIT_TIME is elapsed
    if(this.state.elapsedSeconds > SyncResourceConstants.MAX_WAIT_TIME) {
      clearInterval(this.interval);
    }
    const { classes } = this.props;
    let rasPiRecords = this.getRasPiRecords(this.state.labsAndRasPis.data);
    return (
      <div>
        { this.state.labsAndRasPis.error.isError || this.state.labsAndRasPis.loading ? (
          (() => {
            if (this.state.labsAndRasPis.error.isError) {
              return (
                <AWSUI.Alert
                  header='Cannot retrieve labs at this time. Please contact support-aqt@amazon.com for provisioning Labs.'
                  type='warning'
                ></AWSUI.Alert>
              )
            } else {
              return ( <div className="awsui-util-t-c"><Spinner size="large"/></div> )
            }
          })()
        ) : (
            rasPiRecords && rasPiRecords.length > 0 ? (
              <Grid disableGutters gridDefinition={[{colspan: 2}, {colspan: 9}]}>
                  <div className={classes.nav}>
                      <SideNavigation
                          header={{ href: '', text: 'Labs' }}
                          activeHref={this.state.expandedLab === AppConstants.EMPTY ? rasPiRecords[0].href : this.state.expandedLab}
                          onFollow={event => {
                              event.preventDefault();
                              this.setState({
                                  expandedLab: event.detail.href
                              })
                          }
                          }
                          items={rasPiRecords}
                      />
                  </div>
                  <Box margin={{ top: "xxxl"}} padding={{ top: "xxxl"}}>
                      { this.state.expandedLab === AppConstants.EMPTY ?
                          this.getSectionForLab(rasPiRecords[0].href, this.state.labsAndRasPis.data) :
                          this.getSectionForLab(this.state.expandedLab, this.state.labsAndRasPis.data)
                      }
                  </Box>
              </Grid>
          ) : (
              <AWSUI.Alert
                header='No Labs setup. Please contact support-aqt@amazon.com for provisioning Labs.'
                type='warning'
              ></AWSUI.Alert>
          )
        )}
      </div>
    );
  }
}

Labs.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object
};

export default withStyles(styles, {params:{}})(Labs);
