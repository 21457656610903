import React, {useEffect, useLayoutEffect, useState, useRef} from 'react';
import {
  Box,
  SpaceBetween,
  Badge,
  Link,
  ContentLayout,
  Modal,
  Textarea
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import _ from 'lodash';
import {aplRenderer} from "./aplRenderer";

function Api({ device, setApl }) {
  const scroll = useRef();
  const [userScroll, setUserScroll] = useState(false);
  const [messages, setMessages] = React.useState([]);
  const [logs, setLogs] = React.useState(null);

  useEffect(() => {
    if (device) {
      device.startstream();
      const unsubApi = device.avsapi().subscribe(stateChange);
      const unsubHistory = device.history().subscribe(historyChange, i => i.type == 'APL');
      return () => { unsubApi(); unsubHistory(); }
    }
  }, [device]);

  useLayoutEffect(() => {
    if (scroll && scroll.current) {
      const ref = scroll.current;
      const handleScroll = () => {
        // Debug this
        setUserScroll(ref.scrollTop < ref.scrollHeight - ref.clientHeight);
      };
      ref.addEventListener('scroll', handleScroll);
      return () => {
        ref.removeEventListener('scroll', handleScroll);
      };
    }
  });

  useLayoutEffect(() => {
    if (!userScroll && scroll.current) {
      scroll.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [userScroll, messages]);

  const stateChange = (d) => {
    setMessages([...d]);
  }

  const historyChange = async (d) => {
    const m = _.findLast(d.history(), { type: 'APL' });
    if (m) {
      setApl(true);
      await aplRenderer.renderApl(m.content, "apl");
    }
  }

  const bubble = (badge, color, status, content, float) => {
    const header =  content.namespace + "." + content.name;
    return (
      <SpaceBetween direction="horizontal" size="xs">
        {float === 'left' && (<Badge color={color}> {badge} </Badge>)}
        <Box>
          <Link variant="secondary" onFollow={(event) => {
            event.preventDefault();
            setLogs(content.data);
          }}>
            {header}
          </Link>
        </Box>
        {float === 'right' && (<Badge color={color}> {badge} </Badge>)}
      </SpaceBetween>
    );
  }

  return (
        <ContentLayout
          defaultPadding
          disableOverlap
        >
          <Modal
            onDismiss={() => setLogs(null)}
            visible={logs}
            header="API Payload"
          >
            <Textarea
              value={JSON.stringify(logs, null, 2)}
              rows={30}
              size="max"
              readOnly
            />
          </Modal>
          <SpaceBetween direction="vertical" size="s">
            {messages.length != 0 && messages.map((item, index) => (
              <React.Fragment key={`${item.type}_${index}`}>
                {item.type === 'event' && (
                  <Box margin="xxxs">
                    {bubble("e", "grey", "text-status-info", item, "left")}
                  </Box>
                )}
                {item.type === 'directive' && (
                  <Box margin="xxxs" float="right">
                    {bubble("d", "grey", "text-status-success", item, "right")}
                  </Box>
                )}
              </React.Fragment>
            ))}
            <span ref={scroll} />
          </SpaceBetween>
      </ContentLayout>
  )
}

export default React.memo(Api);
