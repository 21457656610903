export const CATEGORY = {
  CALLING: 'Calling',
  DROP_IN: 'Drop In',
  INTERRUPT: 'Interrupt',
  DISAMBIGUATION: 'Disambiguation',
  DND: 'DND',
  LONGEVITY: 'Longevity',
  MESSAGING: 'Messaging',
}


export const SCENARIO_NAME = {
  CALL_MULTITURN: 'Multiturn Call - 302',
  CALL_MULTITURN_PROXY: 'Multiturn Call using Proxy - 302',
  CALL_BARGE_IN: 'Call Barge-in - 304',
  SELF_CALL: 'Self Call - 305',
  SELF_DROP_IN: 'Self Drop-in - 306',
  DROPIN_MULTITURN: 'Multiturn Call - 310',
  INTERRUPT_DROPIN: 'Drop In - 701',
  INTERRUPT_ALERT: 'Alerts - 702',
  A2A_CALLING: 'A2A Calling - 703',
  ACTIVE_CALL: 'Active Call - 706',
  INTERRUPT_MESSAGING: 'Messaging - 708',
  DEVICE_NAME_DISAMBIGUATION: 'Device Name Disambiguation - 801',
  DEVICE_NAME_DISAMBIGUATION_PROXY: 'Device Name Disambiguation using proxy - 801',
  OUTGOING_CALL: 'Outgoing Call - 903',
  LONGEVITY: 'Longevity - 1501',
  MESSAGING: 'Do not disturb - 1714',
}

export const UNNAMED_DEVICE = "Unnamed Device";

export const GOTO_STEP = {
  TEST_SUITE: -1,
  SCEN_SELECTION: 0,
  LAB_CONFIG: 1,
  PREVIEW: 2
}

export const TEST_LIST_STORAGE = 'new_run.test_list';

export const DEVICES = {
  Andy_First_Floor: "Andy_First_Floor",
  Andy_Second_Floor: "Andy_Second_Floor",
  Andy_Third_Floor: "Andy_Third_Floor",
  Andy_Echo: "Andy_Echo",
  Bob_Kitchen: "Bob_Kitchen",
  Bob_Echo: "Bob_Echo",
  Chris_Kitchen: "Chris_Kitchen",
  Chris_Echo: "Chris_Echo",
  David_Kitchen: "David_Kitchen",
  David_Echo: "David_Echo",
}

export const ACC_DEV_MAPPING = {
  ACCOUNT1: {
    label: 'Andy Jones',
    devices: {
      Andy_First_Floor: {
          type: 'DUT',
          label: "First Floor",
          id: "Andy_First_Floor"
      },
      Andy_Second_Floor: {
          type: 'DUT',
          label: "Second Floor",
          id: "Andy_Second_Floor"
      },
      Andy_Third_Floor: {
          type: 'DUT',
          label: "Third Floor",
          id: "Andy_Third_Floor"
      },
      Andy_Echo: {
          type: 'Echo Family',
          label: 'Andy Echo',
          id: 'Andy_Echo'
      }

    }
  },
  ACCOUNT2: {
    label: 'Bob Jones',
    devices: {
      Bob_Kitchen: {
          type: 'DUT',
          label: "Bob Kitchen",
          id: "Bob_Kitchen"
      },
      Bob_Echo: {
          type: 'Echo Family',
          label: 'Bob Echo',
          id: 'Bob_Echo'
      }

    }
  },
  ACCOUNT3: {
    label: 'Chris Jones',
    devices: {
      Chris_Kitchen: {
          type: 'DUT',
          label: "Chris Kitchen",
          id: "Chris_Kitchen"
      },
      Chris_Echo: {
          type: 'Echo Family',
          label: 'Chris Echo',
          id: 'Chris_Echo'
      }

    }
  },
  ACCOUNT4: {
    label: 'David Jones',
    devices: {
      David_Kitchen: {
          type: 'DUT',
          label: "David Kitchen",
          id: "David_Kitchen"
      },
      David_Echo: {
          type: 'Echo Family',
          label: 'David Echo',
          id: 'David_Echo'
      }
    }
  }
}

export const TESTID_DEVICE_MAP = {
  "@302": {
    scenario: SCENARIO_NAME.CALL_MULTITURN,
    devices: [DEVICES.Andy_First_Floor, DEVICES.David_Kitchen]
  },
  "@302proxy": {
    scenario: SCENARIO_NAME.CALL_MULTITURN_PROXY,
    devices: [DEVICES.Andy_First_Floor, DEVICES.David_Kitchen]
  },
  "@304": {
    scenario: SCENARIO_NAME.CALL_BARGE_IN,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Andy_Echo],
  },
  "@305":{
    scenario: SCENARIO_NAME.SELF_CALL,
    devices: [DEVICES.David_Kitchen],
  },
  "@306":{
    scenario: SCENARIO_NAME.SELF_DROP_IN,
    devices: [DEVICES.David_Kitchen],
  },
  "@310":{
    scenario: SCENARIO_NAME.DROPIN_MULTITURN,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Bob_Echo],
  },
  "@701":{
    scenario: SCENARIO_NAME.INTERRUPT_DROPIN,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Andy_Second_Floor, DEVICES.Andy_Third_Floor],
  },
  "@702":{
    scenario: SCENARIO_NAME.INTERRUPT_ALERT,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Andy_Echo],
  },
  "@703":{
    scenario: SCENARIO_NAME.A2A_CALLING,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Bob_Kitchen],
  },
  "@706":{
    scenario: SCENARIO_NAME.ACTIVE_CALL,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Bob_Echo, DEVICES.David_Echo],
  },
  "@708":{
    scenario: SCENARIO_NAME.INTERRUPT_MESSAGING,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Andy_Echo, DEVICES.Bob_Echo],
  },
  "@801":{
    scenario: SCENARIO_NAME.DEVICE_NAME_DISAMBIGUATION,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Andy_Second_Floor, DEVICES.Andy_Third_Floor]
  },
  "@801proxy":{
    scenario: SCENARIO_NAME.DEVICE_NAME_DISAMBIGUATION_PROXY,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Andy_Second_Floor, DEVICES.Andy_Third_Floor]
  },
  "@903":{
    scenario: SCENARIO_NAME.OUTGOING_CALL,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Andy_Second_Floor]
  },
  "@1501":{
    scenario: SCENARIO_NAME.LONGEVITY,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Bob_Echo],
  },
  "@1714":{
    scenario: SCENARIO_NAME.MESSAGING,
    devices: [DEVICES.Andy_First_Floor, DEVICES.Andy_Second_Floor]
  },
}

export const CATEGORY_SCENARIO_MAP = {
  [CATEGORY.CALLING]: [
    {
      scenario: SCENARIO_NAME.CALL_MULTITURN,
      DUT: 2,
      scenarioId: "@302",
    },
    {
      scenario: SCENARIO_NAME.CALL_BARGE_IN,
      DUT: 1,
      scenarioId: "@304",
    },
    {
      scenario: SCENARIO_NAME.SELF_CALL,
      DUT: 2,
      scenarioId: "@305",
    }
  ],
  [CATEGORY.DROP_IN]: [
    {
      scenario: SCENARIO_NAME.SELF_DROP_IN,
      DUT: 2,
      scenarioId: "@306",
    },
    {
      scenario: SCENARIO_NAME.DROPIN_MULTITURN,
      DUT: 1,
      scenarioId: "@310",
    },
  ],
  [CATEGORY.INTERRUPT]: [
    {
      scenario: SCENARIO_NAME.INTERRUPT_DROPIN,
      DUT: 3,
      scenarioId: "@701",
    },
    {
      scenario: SCENARIO_NAME.INTERRUPT_ALERT,
      DUT: 1,
      scenarioId: "@702",
    },
    {
      scenario: SCENARIO_NAME.A2A_CALLING,
      DUT: 2,
      scenarioId: "@703",
    },
    {
      scenario: SCENARIO_NAME.ACTIVE_CALL,
      DUT: 1,
      scenarioId: "@706",
    },
    {
      scenario: SCENARIO_NAME.MESSAGING,
      DUT: 1,
      scenarioId: "@708",
    },
  ],
  [CATEGORY.DISAMBIGUATION]: [
    {
      scenario: SCENARIO_NAME.DEVICE_NAME_DISAMBIGUATION,
      DUT: 3,
      scenarioId: "@801",
    }
  ],
  [CATEGORY.DND]: [
    {
      scenario: SCENARIO_NAME.OUTGOING_CALL,
      DUT: 2,
      scenarioId: "@903",
    },
  ],
  [CATEGORY.LONGEVITY]: [
    {
      scenario: SCENARIO_NAME.LONGEVITY,
      DUT: 1,
      scenarioId: "@1501",
    },
  ],
  [CATEGORY.MESSAGING]: [
    {
      scenario: SCENARIO_NAME.MESSAGING,
      DUT: 2,
      scenarioId: "@1714",
    },
  ],
}


export const TEST_SUITE = {ACM: 'ACM'}

export const LOCALE_LIST = ['en_US']
