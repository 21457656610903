
export const STOP_MORGAN_NOISE = {
  "action": "NOISE_PLAYBACK",
  "type": "mdx_plugin_aqt_automotive",
  "payload": {
    "operation": "NOISE_PLAYBACK",
    "action": "STOP"
  }
}

export const HEALTH_CHECK = [
  {
    "name": "piConn",
    "desc": "Pi connectivity...",
    "wait": "5000",
    "payload": {
      "action": "state",
      "state": "todo"
    }
  },
  /*{
    "name": "syncReq",
    "desc": "Sync required...",
    "wait": "5000",
    "payload": {
      "action": "sync_resource",
      "state": "todo",
      "type": "mdxirs",
      "payload": [
        {
          "file_name": "OAK_Common_audioFiles_manifest.json",
          "download_url": "https://dtzsgmefzdeac.cloudfront.net/ManifestFiles/Acoustic/en_US/20220922/OAK_Common_audioFiles_manifest.json",
          "md5": "b0968552ffae303a2c7f57b9e7280385"
        }
      ]
    }
  },
  {
    "name": "micCheck",
    "desc": "Mic check...",
    "payload": {
      "action": "DETECT_RECORDER",
      "type": "mdx_plugin_aqt_audio_recorder",
      "payload": {}
    }
  },*/
  {
    "name": "camCheck",
    "desc": "Camera check...",
    "wait": "10000",
    "payload": {
      "action": "DETECT_USBCAM",
      "type": "mdx_plugin_aqt_tools",
      "payload": {
        "jobId": "UI"
      }
    }
  },
  {
    "name": "guiCheck",
    "desc": "GUI automation check...",
    "wait": "60000",
    "payload": {
      "action": "run_appium_command",
      "type": "mdx_plugin_ui_automation",
      "payload": {
        "actions": [
          { "open_application": {
              "remote_url" : "127.0.0.1:4723",
              "platformName": "Android",
              "automationName": "UIAutomator2"
            }
          },
          { "open_notifications": {} }
        ]
      }
    }
  }
]

export const DEFAULT_PLUGINS = [
    "mdx_plugin_aqt_tools",
    "mdx_plugin_avs_audio",
    "mdx_plugin_aqt_audio_recorder",
    "mdx_plugin_ui_automation"
]

export const PYTHON_VERSION = "3.9.2"
