class APLRenderer {
  constructor() {
    this.currentApl = {};
    this.aplRendererInitialized = false;
    this.aplRendererScriptLoaded = false;
    this.loadScript(
      "https://d1gkjrhppbyzyh.cloudfront.net/apl-viewhost-web/6dcd129a-c82d-46fe-ab8e-93b6f3ed1870/index.js"
    ).then(_ => this.initializeAplRenderer());
  }

  async renderApl(deviceMessages, containerId) {
    while (!this.aplRendererInitialized) {
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
    for (const message of deviceMessages) {
      if (message.name === "UpdateIndexListData") {
        this.processIndexListData(message.data.directive);
      }
      if (message.name === "RenderDocument") {
        const dialogRequestId = message.data.directive.header.dialogRequestId;
        if (this.currentApl.dialogRequestId !== dialogRequestId) {
          if (this.currentApl.renderer) {
            this.currentApl.renderer.destroy();
            this.currentApl.cardContainer.innerHTML = '';
            this.currentApl = {};
          }
          await this.displayAplDocument(message.data.directive, containerId);
        }
      }
    }
  }

  getOrCreateCard(containerId) {
    let cardContainer;
    const containerElement = document.getElementById('apl');
    containerElement.innerHTML = '';
    const outputElement = document.createElement('aplViewer-' + containerId);
    const messageDiv = document.createElement("div");
    messageDiv.className = "message directive";
    messageDiv.id = "aplImage-" + containerId;

    cardContainer = document.createElement("div");
    cardContainer.className = "apl-container";
    cardContainer.id = 'aplViewer-' + containerId;

    messageDiv.appendChild(cardContainer);
    outputElement.appendChild(messageDiv);
    containerElement.appendChild(outputElement);

    return cardContainer;
  }

  async displayAplDocument(message, containerId) {
    const dialogRequestId = message.header.dialogRequestId;
    const cardContainer = this.getOrCreateCard(containerId);

    const aplDocument = message.payload.document;
    const datasources = message.payload.datasources;
    /*const viewport = message.payload.viewport || {
        width: 1024,
        height: 600,
        dpi: 160,
    };*/
    const viewport = {
      width: 580,
      height: 320,
      dpi: 70, // Use a standard DPI value, or adjust based on your target devices
    };

    const content = window.AplRenderer.Content.create(
      JSON.stringify(aplDocument)
    );
    if (content) {
      if (
        aplDocument.mainTemplate &&
        aplDocument.mainTemplate.parameters &&
        Array.isArray(aplDocument.mainTemplate.parameters) &&
        aplDocument.mainTemplate.parameters.length > 0
      ) {
        aplDocument.mainTemplate.parameters.forEach((name) => {
          if (typeof name === "string") {
            const dataSource = datasources[name]
              ? JSON.stringify(datasources[name])
              : "{}";
            content.addData(
              name,
              name === "payload"
                ? JSON.stringify(datasources)
                : dataSource
            );
          }
        });
      }
    }

    const renderer = window.AplRenderer.default.create({
      content: content,
      view: cardContainer,
      environment: {
        agentName: "OAK",
        agentVersion: "1.0",
        allowOpenUrl: true,
        disallowVideo: false,
      },
      viewport: viewport,
      theme: "dark",
      utcTime: Date.now(),
      localTimeAdjustment: -new Date().getTimezoneOffset() * 60 * 1000,
      onSendEvent: (event) => {
        console.log("USER_EVENT: " + JSON.stringify(event));
      },
    });
    await renderer.init();

    this.currentApl = { dialogRequestId, cardContainer, renderer };
  }

  processIndexListData(directive) {
    const data = JSON.stringify(directive.payload);

    if (this.currentApl.renderer) {
      this.currentApl.renderer.processDataSourceUpdate(data, "dynamicIndexList");
    } else {
      console.error("processIndexListData: renderer is not present.");
    }
  }

  loadScript(url) {
    return new Promise((resolve, reject) => {
      if (this.aplRendererScriptLoaded) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = url;
      script.onload = () => {
        this.aplRendererScriptLoaded = true;
        resolve();
      };
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  async initializeAplRenderer() {
    if (!this.aplRendererInitialized) {
      console.log("Initializing APL renderer");
      await window.AplRenderer.initEngine();
      this.aplRendererInitialized = true;
    }
  }
}

export const aplRenderer = new APLRenderer();
