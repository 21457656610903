import axios from 'axios';
import { EventStreamCodec } from '@aws-sdk/eventstream-codec';
import { fromUtf8, toUtf8 } from '@aws-sdk/util-utf8-browser';
import { aqtStore } from "../../Components/State/Store";
import { v4 as uuidv4 } from 'uuid';
import { logToConsole } from "../../Util";

const esCodec = new EventStreamCodec(toUtf8, fromUtf8);
const decoder = new TextDecoder();

const HexClientProxy = () => {

  function url(path, params) {
    return `${endpoint()}/${path}${params ? `?${params.toString()}` : ''}`;
  }

  function gammaUrl(path, params) {
    return `${gammaEndpoint()}/${path}${params ? `?${params.toString()}` : ''}`;
  }

  function authHeader() {
    return { 'x-amz-identity-token':  aqtStore.getState().session.idToken.jwtToken };
  }

  function endpoint() {
    return aqtStore.getState().environment.aqt_hex;
  }

  function gammaEndpoint() {
    return aqtStore.getState().environment.aqt_hex_gamma;
  }

  async function get(path, params) {
    try {
      return await axios.get(url(path, params), {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function getFromGamma(path, params) {
    try {
      return await axios.get(gammaUrl(path, params), {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function put(path, params) {
    try {
      return await axios.put(url(path, params), null, {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function gammaPut(path, params) {
    try {
      return await axios.put(gammaUrl(path, params), null, {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function post(path, params) {
    try {
      return await axios.post(url(path, params), null, {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function gammaPost(path, params) {
    try {
      return await axios.post(gammaUrl(path, params), null, {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function stream(path, params, onMessage, env) {
    try {
      const abortController = new AbortController();
      const { signal } = abortController;
      let response;
      if(env == "prod")
        response = await fetch(url(path, params), {headers: authHeader(), signal});
      else
        response = await fetch(gammaUrl(path, params), {headers: authHeader(), signal});

      if (!response.ok) {
        throw new Error(`HTTP stream error! Status: ${response.status}`);
      }

      const reader = response.body.getReader();
      let buffer = Buffer.alloc(0);

      const processChunks = async () => {
        try {
          while (true) {
            const { done, value } = await reader.read();
            buffer = Buffer.concat([buffer, Buffer.from(value)]);

            let view = new DataView(buffer.buffer, buffer.byteOffset, buffer.byteLength);
            let length = view.getUint32(0, false);

            while (buffer.byteLength >= length) {
              try {
                const chunk = buffer.slice(0, length);
                buffer = buffer.slice(length, buffer.byteLength);

                if (buffer.byteLength > 0) {
                  view = new DataView(buffer.buffer, buffer.byteOffset, buffer.byteLength);
                  length = view.getUint32(0, false);
                }

                const message = esCodec.decode(chunk);
                onMessage({ header: message.headers, body: decoder.decode(message.body) });
              } catch (error) {
                console.log(error); // Debug partial JSON received from live stream
              }
            }

            if (done) break;
          }
        } catch (error) {
          logToConsole(`Error processing chunks ${error}`);
        } finally {
          reader.releaseLock();
        }
      };

      processChunks();

      return async () => {
        abortController.abort();
      }
    } catch(err) {
      throw new Error(err);
    }
  }

  return {
    ping: async function() {
      return await get('ping');
    },
    oakauth: async function (params) {
      return await get('oakauth', params);
    },
    deviceaccounts: async function (params) {
      return await get('deviceaccountswithendpoint', params);
    },
    bedrockgenerate: async function (params) {
      return await get('bedrockgenerate', params);
    },
    injectspeech: async function (params, env) {
      if(env == "prod")
        return await get('injectspeech', params);
      else
        return await getFromGamma('injectspeech', params);
    },
    injecttext: async function (params, text, env) {
      let method = env == "prod" ? post : gammaPost;
      let copy = new URLSearchParams(params.toString());
      copy.append('input', text);
      await method('devicerequests/clear', params);
      await method('devicerequests/inject/NoWake', params);
      await method('devicerequests/inject/Text', copy);
      return await method('devicerequests/inject/ExpectSpeech', params);
    },
    injecttexts: async function (params, texts, env) {
      let method = env == "prod" ? post : gammaPost;
      await method('devicerequests/clear', params);
      for (const text of texts) {
        let copy = new URLSearchParams(params.toString());
        copy.append('input', text);
        await method('devicerequests/inject/Text', copy);
      }
      return await method('devicerequests/inject/ExpectSpeech', params);
    },
    setendpoint: async function (params, env) {
      let method = env == "prod" ? post : gammaPost;
      return await method('devicerequests/setendpoint', params);
    },
    activityitems: async function (params, env) {
      if(env == "prod")
        return await get('activityitems', params);
      else
        return await getFromGamma('activityitems', params);
    },
    alexametadata: async function (params) {
      return await get('alexametadata', params);
    },
    deviceendpoint: async function (params, env) {
      if(env == "prod")
        return await get('deviceendpoint', params);
      else
        return await getFromGamma('deviceendpoint', params);
    },
    switchendpoint: async function (params, env) {
      if(env == "prod")
        return await put('deviceendpoint', params);
      else
        return await gammaPut('deviceendpoint', params);
    },
    liveinteraction: function (params, onMessage, env) {
      return stream('liveinteraction', params, onMessage, env);
    }
  }
}

export const AbesClientProxy = () => {

  function url(path, params) {
    return `${endpoint()}/${path}${params ? `?${params.toString()}` : ''}`;
  }

  function authHeader() {
    return { Authorization: `Bearer ${aqtStore.getState().session.idToken.jwtToken}` };
  }

  function endpoint() {
    return aqtStore.getState().environment.controllerEndpoint;
  }

  async function post(path, params, payload) {
    try {
      return await axios.post(url(path, params), payload, {headers: authHeader()});
    } catch (err) {
      throw new Error(err);
    }
  }

  async function mqttmessage(params, payload) {
    return await post('api/mqtt/message', params, payload);
  }

  return {
    sdcfeature: async function (payload) {
      const id = uuidv4();
      const payloadplus = {
        requestTopic: `aqtservice-usamazon/sdcfeature/${id}/request`,
        responseTopic: `aqtservice-usamazon/sdcfeature/${id}/response`,
        payload
      };
      return await mqttmessage(new URLSearchParams({ waitForActionTime: 10000 }), payloadplus);
    },
    senddirective: async function (payload) {
      const id = uuidv4();
      const payloadplus = {
        requestTopic: `aqtservice-usamazon/senddirective/${id}/request`,
        responseTopic: `aqtservice-usamazon/senddirective/${id}/response`,
        payload
      };
      return await mqttmessage(new URLSearchParams({ waitForActionTime: 10000 }), payloadplus);
    }
  }

}

export const HexClient = HexClientProxy();
export const AbesClient = AbesClientProxy();
