import '@amzn/awsui-components-react/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import HeadBar from './Components/HeadBar';
import FootBar from './Components/FootBar';
import { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux';
import createStore from './redux/store';

const store = createStore();

const Portal = () => {
    return (
        <Provider store={store}>
            <Router>
                <div className="header"><HeadBar/></div>
                <div className='awsui'><App/></div>
                <div className="footer"><FootBar/></div>
            </Router>
        </Provider>
    )
}

ReactDOM.render(<Portal />, document.getElementById('root'));

unregister();
