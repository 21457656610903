import React from 'react';
import PropTypes from 'prop-types';
import AWSUI from '@amzn/awsui-components-react';
import { fetchRunningJobs, terminateJob } from './controller';
import { CARD_DEFINITION, CARD_PER_ROW } from './CardConfig';
import { liveFeedStore } from '../../Components/State/Store';
import LiveFeed from '../../Components/LiveFeed';
import { getTestTypeToDisplay, getDisplayNameForId, logToConsole, getFunctionalScenarios,
  getTestTypeHeaderAutoLocalSearch } from '../../Util';
import AppConstants from '../../_Constants/AppConstants';
import MusicConstants from '../../_Constants/MusicConstants';
import FunctionalTestCases from '../../_Constants/FunctionalTestCasesConstants';
import ACMLiveRun from "../../Components/LiveFeed/ACM/Liverun";
import ld from "lodash";

class LiveRun extends React.Component {
  _mounted = false;

  state = {
    liveFeed: {},
    loadingJobs: true,
    loadErr: false,
    terminatingJobs: {},
    confirm: {}
  };

  componentDidMount() {
    this._mounted = true;
    const { params = {} } = this.props.location || {};
    this.loadJobs(params.lazyLoadMillis);
  }

  componentWillMount() {
    this.unsubscribeLive = liveFeedStore.subscribe(this.updateLiveFeedsState);
  }

  componentWillUnmount() {
    this._mounted = false;
    clearTimeout(this.lazyLoadJobs);
    Object.keys(liveFeedStore.getState().liveFeed).forEach(labJobId => {
      if (liveFeedStore.getState().liveFeed[labJobId].hasOwnProperty('socketClose')) {
        liveFeedStore.getState().liveFeed[labJobId].socketClose();
      }
    });
    this.unsubscribeLive();
  }

  updateLiveFeedsState = () => {
    const { liveFeed } = liveFeedStore.getState();
    this.setState({
      liveFeed,
    });
  };

  loadJobs = (lazyLoadMillis=0) => {
    this.lazyLoadJobs = setTimeout(() => {
      fetchRunningJobs().then(jobs => {
        if (!jobs.hasOwnProperty('error')) {
          this.jobs = jobs;
          this._mounted && this.setState({
            loadingJobs: false,
            loadErr: false
          });
        } else {
          this._mounted && this.setState({
            loadingJobs: false,
            loadErr: jobs.error
          });
        }
      });
    }, lazyLoadMillis);
  }

  getLiveRuns = () => {
    let liveRuns = [];
    this.jobs.forEach(labJob => {
      let jobOptions = {};
      try {
        jobOptions = JSON.parse(liveFeedStore.getState().liveFeed[labJob.id].labJob.options);
        liveRuns.push({
          id: labJob.id,
          options: jobOptions,
          getFeeds: this.getFeeds,
          getHeader: this.getHeader,
          executionOrder: jobOptions.executionOrder ? jobOptions.executionOrder : 0
        });
      } catch (ex) {
        logToConsole(JSON.stringify(ex));
      }
    });
    // Sort jobs based on execution order defined while submitting experiment (applicable when multiple
    // jobs are submitted at the same time)
    liveRuns.sort(function(x, y) {
      if (x['executionOrder'] > y['executionOrder']) {
        return 1;
      }
      if (x['executionOrder'] < y['executionOrder']) {
        return -1;
      }
      return 0;
    });
    return liveRuns;
  }

  getFeeds = (jobId) => {
    const liveFeed = this.state.liveFeed;
    const jobRequest = liveFeed[jobId].request;
    let options = JSON.parse(jobRequest.options);
    // Testsuite is present in options.testOptions For New Run tests
    const testSuite = ld.get(options, "testSuite") || ld.get(options.testOptions, "testSuite");

    if (!ld.isNil(testSuite)) {
      // TODO: default is not set at the moment since we plan to start developing newer
      // liverun component as MFE and all the component requires is jobRequest and redis data
      // eslint-disable-next-line default-case
      switch (jobRequest.scenarioName) {
        case "AQTUDTV2":
          return (
            // new live run component gets the job request and redis data directly
            // component is subscribed to redis state changes and hence it will
            //re-render on state changes
            <ACMLiveRun request={jobRequest} data={liveFeed[jobId]["v2data"]} />
          );
      }
    }
    return (
      // Older Live Run viz.
      <LiveFeed
        params={{ labJobId: jobId, testDetails: this.getTestDetails(jobId) }}
      />
    );
  };

  getTestDetails = (jobId) => {
    let testDetails = {};
    this.jobs.forEach(labJob => {
      if (labJob.id === jobId) {
        let options = JSON.parse(labJob.options);
        let mapping = JSON.parse(labJob.mapping);
        testDetails = {
          customerId: mapping.deviceMapping.DUT.customerId,
          dsn: mapping.deviceMapping.DUT.dsn,
          deviceName: options.testOptions.customOptions.deviceName,
          buildInfo: mapping.deviceMapping.DUT.buildInfo,
          marketPlace: options.marketPlace,
          testName: options.testName,
          testType: options.testType,
          testCategory: options.testOptions.testCategory,
          scenarioType: options.testOptions.scenarioType,
          customOptions: options.testOptions.customOptions,
          hotspotOptions: options.testOptions.hotspotOptions,
          deviceType: options.testOptions.amazonId,
          labName: labJob.labName,
          ownerEmail: labJob.ownerEmail,
          testSuite: options.testOptions.testSuite,
          sendCompletionEmail: labJob.sendCompletionEmail,
          customizedTestType: options.testOptions.customOptions.customizedTestType,
          labJobId: labJob.id,
          startTime: labJob.createdAt,
          config: options.testOptions.config,
          state: labJob.state
        };
      }
    });
    return testDetails;
  }

  getJobEmptyOrErr = () => {
    if (this.state.loadingJobs) {
      return (<div align='center'><AWSUI.Spinner size='large' /></div>)
    } else if (this.state.loadErr) {
      return (<AWSUI.Alert
                header='Cannot retrieve jobs at this time'
                content={ this.state.loadErr }
                type='error'
              ></AWSUI.Alert>)
    } else if (Object.keys(liveFeedStore.getState().liveFeed).length <= 0) {
      return (<AWSUI.Alert
                header='No labs can be retrieved for the account'
                content='Please make sure there are labs associated with the account.'
                type='warning'
              ></AWSUI.Alert>)
    }
  }

  getHeader = (jobOptions, labJobId) => {
    let terminateButtonId = labJobId + '_terminateButton';
    return (
      <div className='awsui-util-container awsui-util-no-gutters awsui-util-m-n'>
        <div className='awsui-util-container-header'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2 className='awsui-text-secondary'>{ this.getTestHeader(jobOptions) } </h2>
            </div>
            <div className='awsui-util-action-stripe-group'>
              <AWSUI.Button text='Terminate' variant='primary' icon='status-negative' id={ terminateButtonId }
                disabled={ this.state.terminatingJobs.hasOwnProperty(labJobId) }
                loading={ this.state.terminatingJobs[labJobId] ? this.state.terminatingJobs[labJobId] : false }
                onClick={ () => {
                  this.setState({
                    confirm: {
                      pop: true,
                      title: 'Confirm Termination',
                      message: 'Do you want to terminate the test?',
                      callback: (action) => {
                        if (action) {
                          this.terminateLabJob(labJobId);
                        }
                      }
                    }
                  });
                }}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Gets test header to display at the top of live run
   * @param jobOptions Job Options
   * @return Header to display at the top of test
   */
  getTestHeader = (jobOptions) => {
    const { testOptions: { testSuite, scenarioType, testCategory, config }, testName } = jobOptions;
    let testHeader = AppConstants.EMPTY;
    const isAcousticSuite = !testSuite || testSuite === AppConstants.ACOUSTIC_SCENARIO_ID;
    const isMusicSuite = testSuite === MusicConstants.MUSIC_SCENARIO_ID;
    const isFunctionalSuite = testSuite === AppConstants.FUNCTIONAL_SCENARIO_ID;
    const isCloseTalkSuite = testSuite === AppConstants.CLOSE_TALK_SCENARIO_ID;
    const isMobileSuite = testSuite === AppConstants.MOBILE_SUITE_ID;
    const isSecuritySuite = testSuite === AppConstants.SECURITY_SCENARIO_ID;
    const isAutoLocalSearchSuite = testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID;
    const isStabilitySuite = testSuite === AppConstants.STABILITY_SCENARIO_ID;
    const isUPLSuite = testSuite === AppConstants.UPL_SCENARIO_ID;
    const isQualSuite = testSuite === AppConstants.QUAL_SCENARIO_ID;
    const isCustomSuite = testSuite === AppConstants.CUSTOM_SCENARIO_ID;
    const isAutomotiveSuite = testSuite === AppConstants.AUTOMOTIVE_SCENARIO_ID;
    const isAcavSuite = testSuite === AppConstants.ACAV_SCENARIO_ID;

    if (isSecuritySuite) {
      testHeader = AppConstants.SECURITY_TEST_HEADER;
    } else if (isAutoLocalSearchSuite) {
      testHeader = getTestTypeHeaderAutoLocalSearch(scenarioType);
    } else if (isAcousticSuite || isCloseTalkSuite || isMobileSuite || isQualSuite || isAutomotiveSuite) {
      testHeader = getTestTypeToDisplay(testSuite, scenarioType, testCategory,
        jobOptions.testType);
    } else if (isStabilitySuite || isUPLSuite || isAcavSuite) {
      testHeader = getTestTypeToDisplay(testSuite, scenarioType, AppConstants.EMPTY, AppConstants.EMPTY);
    } else if (isMusicSuite) {
      testHeader = getDisplayNameForId(MusicConstants.MSP_TYPE, scenarioType);
    } else if (isFunctionalSuite) {
      testHeader = FunctionalTestCases.FUNCTIONAL_LABEL +
        getDisplayNameForId(getFunctionalScenarios(), scenarioType);
    } else if (isCustomSuite) {
      if (config && config.name) {
        testHeader = `${config.name}`;
      }
    }
    if (!isSecuritySuite && !isAutoLocalSearchSuite) {
      testHeader = testHeader ? `${testHeader} - ${testName}` : testName;
    }
    return testHeader;
  }

  terminateLabJob = labJobId => {
    this.setState({
      terminatingJobs: {
        ...this.state.terminatingJobs,
        [labJobId]: true
      }
    });
    terminateJob(labJobId).then(response => {
      if (!response.hasOwnProperty('error')) {
        this.setState({
          loadingJobs: true,
          loadErr: false,
          terminatingJobs: {
            ...this.state.terminatingJobs,
            [labJobId]: false
          },
          confirm: {
            pop: true,
            title: 'Termination Status',
            message: 'Test cancellation request submitted. It will take up to 2 minutes for test termination!',
            callback: () => {}
          }
        });
        this.loadJobs();
      } else {
        let currTerminatingJobs = Object.assign({}, this.state.terminatingJobs);
        delete currTerminatingJobs[labJobId];
        this.setState({ terminatingJobs: currTerminatingJobs });
        this.setState({
          confirm: {
            pop: true,
            title: 'Termination Status',
            message: 'Test cancellation submission failed. ' + response.error,
            callback: () => {}
          }
        });
      }
    });
  }

  getConfirmation() {
    return (
      <AWSUI.Modal
        content={ this.state.confirm.message }
        visible={ this.state.confirm.pop }
        header={ this.state.confirm.title }
        expandToFit={ true }
        footer={ <span className='awsui-util-f-r'>
            <AWSUI.Button variant='link' text='Cancel'
              onClick={ () => { this.state.confirm.callback(false); this.setState({ confirm: {} }); } }>
            </AWSUI.Button>
            <AWSUI.Button variant='primary' text='Ok'
              onClick={ () => { this.state.confirm.callback(true); this.setState({ confirm: {} }); } }>
            </AWSUI.Button>
          </span> }
      ></AWSUI.Modal>
    )
  }

  render() {
    return (
      <div>
        { this.state.loadingJobs || this.state.loadErr ? (
            this.getJobEmptyOrErr()
        ) : (
          <AWSUI.Cards
            className="awsui-util-p-n"
            cardsPerRow={ CARD_PER_ROW }
            cardDefinition={ CARD_DEFINITION }
            items={ this.getLiveRuns() }
            header={ <h2> Running Tests </h2> }
          >
            <AWSUI.CardsFiltering />
            <AWSUI.CardsPagination />
          </AWSUI.Cards>
        )}
        <div>
          { this.state.confirm.pop && (
            this.getConfirmation()
          )}
        </div>
      </div>
    );
  }
}

LiveRun.propTypes = {
  lazyLoadMillis: PropTypes.number
};

export default LiveRun;
