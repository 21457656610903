export const OVERVIEW_COLUMNS = [{
    id: 'raspiName',
    header: 'RasPi Name',
    cell: item => ( item.name ),
    minWidth: '200px',
    allowLineWrap: true
  }, {
    id: 'status',
    header: 'Status',
    cell: item => ( item.getPingStatus(item.labId, item.rasPiIndex) ),
    minWidth: '100px',
    allowLineWrap: false
  }];

export const DISTROS = [
  {
    text: "Debian",
    items: [
      { text: "Raspbian", id: "raspbian" },
      { text: "Ubuntu", id: "ubuntu" },
      { text: "Others", id: "debian" }
    ]
  }
]
