import React from 'react';
import { Box } from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';

class FootBar extends React.Component {
  render() {
    return (
      <Box color="text-body-secondary" textAlign="center">
        © {new Date().getFullYear()}, Amazon.com, Inc. or its affiliates
      </Box>
    );
  }
}

export default FootBar;
