import { addTest, deleteTest } from "./testListSlice";
import { saveState } from "../../../services/localStorage";
import {API_STATUS, GOTO_STEP, TEST_LIST_STORAGE, URL_MAPS} from "../../../constants";
import { setTestSuite } from "../testInitSlice";
import { setId, setName,setCategoryAndScenario, setEnableEmailNotification, setLocale, setScenarioType } from "../acm/scenarioSelectionSlice";
import { setAccountConfig, setLab } from "../acm/labConfigSlice";
import { updateStep } from "../acm/uiSlice";
import client from "../../../services/client";
import { getUrl } from "../../../services/utils";
import { setInitiateDeleteTest, setStatus as setExecuteStatus} from "./testListUiSlice";
import { get } from "lodash";
import { nanoid } from "@reduxjs/toolkit";
import { resetMapping, setMapping } from "../mappingSlice";
import { setTestOpt } from "../testOptsSlice";

export const addNewTestInTestList = (testInfo) => (dispatch, getState) => {
  dispatch(addTest(testInfo))
  dispatch(resetMapping())
  saveState(getState().testList.tests, TEST_LIST_STORAGE);
}

export const deleteTestFromTestList = () => (dispatch, getState) => {
  dispatch(deleteTest());
  saveState(getState().testList.tests, TEST_LIST_STORAGE);
  dispatch(setInitiateDeleteTest(false));
}

export const loadTest = (testId) => (dispatch, getState) => {
  const testList = getState().testList;
  const selectedTest = testList.tests.find(testObj => testObj.id === testId);
  if(selectedTest) {
    const {
      id,
      testSuite,
      name,
      accountConfig,
      customizedScenarios,
      enableEmailNotification,
      labInfo,
      locale,
      scenarioType,
      testMapping,
      testOpts,
    } = selectedTest;
    dispatch(setTestSuite(testSuite));
    dispatch(setName(name));
    dispatch(setId(id));
    dispatch(setLocale(locale));
    dispatch(setScenarioType(scenarioType));
    dispatch(setEnableEmailNotification(enableEmailNotification));
    dispatch(setMapping(testMapping));
    dispatch(setTestOpt(testOpts));
    if(Array.isArray(customizedScenarios)) {
      customizedScenarios.map(
        ({category, scenarios}, index) => {
          dispatch(setCategoryAndScenario({
            category,
            scenarios,
            index,
          }))
      }
      );
    }
    dispatch(setLab(labInfo));
    Object.entries(accountConfig)
      .forEach(
        ([account, {customerIdInfo, devicePiConfig}]) => {
          dispatch(setAccountConfig({
            account,
            customerIdInfo,
            devicePiConfig,
          }))
        }
      )
    dispatch(updateStep(GOTO_STEP.PREVIEW));
  }

}


export const executeTest = () => async (dispatch, getState) => {
  const {testList} = getState();
  const selectedTest = testList.tests.filter(test => test.isSelected);
  if(selectedTest.length > 0) {

    const scenarioId = get(selectedTest, "[0].testSuite.scenarioId", null);
    dispatch(setExecuteStatus({
      status: API_STATUS.PENDING
    }));

    try {
      const experiementResponse = await client.post(getUrl(URL_MAPS.EXPERIMENT), {
        name: nanoid(),
        scenarioId: scenarioId
      });

      const promiseArr = selectedTest.map(
        (test, ind) => {
          const payload = transformPayload(test, experiementResponse.id, ind);
          return variantsApi(payload, experiementResponse.id);
        }
      )

      await Promise.all(promiseArr);
      const jobsUrl = `${getUrl(URL_MAPS.EXPERIMENT)}/${experiementResponse.id}/runs`;
      await client.post(jobsUrl, {
        launchSequentially: true,
        sendCompletionEmail: false
      });

      dispatch(setExecuteStatus({
        status: API_STATUS.SUCCESS
      }));
      dispatch(deleteTest());
      dispatch(resetMapping());
      saveState(getState().testList.tests, TEST_LIST_STORAGE);

    } catch(e) {
      dispatch(setExecuteStatus({
        status: API_STATUS.ERROR,
        error: "Failed to execute test. Retry again"
      }))
    }
  }
}

const variantsApi = async (payload, id) => {
  const vairantUrl = `${getUrl(URL_MAPS.EXPERIMENT)}/${id}/variants`;
  return await client.post(vairantUrl, payload);
}


const transformPayload = (test, expId, index) => {
  const {name, locale, customizedScenarios, accountConfig, scenarioType} = test;
  let features;
  let deviceMapping;
  let actorMapping;
  let opts = {
    testName: name,
    locale,
    testSuite: test.testSuite.displayName,
    executionOrder: index,
  };

  if (get(test,'testSuite.displayName','').toUpperCase() === 'ACM') {
    if (Array.isArray(customizedScenarios) && customizedScenarios.length > 0) {
      features = customizedScenarios.reduce(
        (iter, {scenarios}) => {
          iter.push(...scenarios);
          return iter;
        }, []
      );
      opts['testOptions'] = {features: [...new Set(features)]};
      opts['subType'] = opts.testSuite
      const {deviceMap, actorMap} = getDeviceActorMapping(accountConfig);
      actorMapping = actorMap;
      deviceMapping = deviceMap;
    }
  } else if (scenarioType === 'wwa') {
      features = getFeatureListForWWA(test);
      opts['testOptions'] = {features: [...new Set(features)]};
      opts['subType'] = opts.testSuite
      actorMapping = test.testMapping.actorMapping;
      deviceMapping = test.testMapping.deviceMapping;
  } else {
    actorMapping = test.testMapping.actorMapping;
    deviceMapping = test.testMapping.deviceMapping;
    opts['testOptions'] = test.testOptions  // TODO: OAK ENABLEMENT | clean up testOptions
    opts['labDependenciesUri'] =  test.testOptions.labDependenciesUri;
  }

  return {
    name: nanoid(),
    order: index,
    labId: test.labInfo.id,
    experimentId: expId,
    stateMachineType: "PhysicalMDXSimulationStateMachineV2Arn",
    customMapping: {
      actorMapping,
      deviceMapping,
      noiseMapping: {}
    },
    options: opts,
  }
}

const getDeviceActorMapping = (accountConfig) => {
  const deviceMap = {};
  const actorMap = {};
  const deviceThingMap = {}
  Object.values(accountConfig)
  .forEach(
    (accountInfo) => {
      const { devicePiConfig } = accountInfo;
      devicePiConfig.forEach(
        ({ dsnInfo, pi }) => {
          const { deviceOrderId, dsn, namespace, name, customerId, buildInfo, typeId } = dsnInfo;
          const { thingName } = pi;
          deviceMap[deviceOrderId] = {
            dsn,
            namespace,
            deviceType: typeId,
            deviceName: name,
            customerId: customerId,
            buildInfo,
            amazonId: typeId,
          }
          actorMap[deviceOrderId] = thingName;
        }
      )
    }
  )
  return {deviceMap, actorMap, deviceThingMap};
}

// Temporary Work around for WWA to filter the test scenarios based on selection
const getFeatureListForWWA = (test) => {
  let features = [];
  const {customizedScenarios} = test;
  if (Array.isArray(customizedScenarios) && customizedScenarios.length > 0) {
    features = customizedScenarios.reduce(
      (iter, {scenarios}) => {
        iter.push(...scenarios);
        return iter;
      }, []
    );
  }

  const deviceAttributes = get(test,'deviceAttributes',{});
  const enabledDeviceAttrs = Object.keys(deviceAttributes).filter(key => deviceAttributes[key])
  let customizedFeatureTag = [];
  features.map(feature => {
    let tags = [];
    tags.push(feature)
    // TODO tag expression may not work since WWA has large set of conditions based on device type.
    // TODO In upcoming release, we need to change the list of test cases based on device type.
    // Common tag expression that will be applicable for test plans.
    //Exclude test cases if the device is IR blaster.
    if (enabledDeviceAttrs.includes("irBlasters")) {
      tags.push("not @ir-blaster-na")
    }

    //Exclude test cases if the device is ACK enabled.
    if (enabledDeviceAttrs.includes("ackEnabled")) {
      tags.push("not @ack-na")
    }

    //Exclude test cases if the device doesn't have physical control.
    if (! enabledDeviceAttrs.includes("physicalControl")) {
      tags.push("not @physicalControl")
    }

    // Add tag expression based on the custom options for power
    if (feature === "@power") {
      if (enabledDeviceAttrs.includes("dimmable")) {
        tags.push("not @dimmable-na")
      }

      if (features.includes("@color")) {
        tags.push("not @color-na")
      }
      if (features.includes("@colortemperature")) {
        tags.push("not @color-temperature-na")
      }
    }
    if (feature === "@color") {
      if (!features.includes("@colortemperature")) {
        tags.push("not @color-temp")
      }
    }
    customizedFeatureTag.push(tags.join(" and "))
  })
  return customizedFeatureTag;
}
