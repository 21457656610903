import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const TYPE = {
    PENDING: 'Pending',
    FAILED: 'Failed',
    PROCESSING: 'Processing',
    ALEXA: 'Alexa',
    HUMAN: 'Human',
    APL: 'APL'
}

export const Item = (content, type) => {
    return {
        id: uuidv4(),
        type: type,
        content: content,
        starttime: Math.floor(new Date().getTime() / 1000)
    }
};

const size = 25;

export function History(dsn, deviceType) {
    let history = [];
    let subscribers = [];

    this.dsn = dsn;
    this.type = deviceType;
    this.history = () => history;

    const publish = (item) => {
        subscribers.forEach(s => s.filter(item) && s.callback(this));
    };

    this.getItem = (id) => {
        return _.find(history, { id: id });
    }

    this.addItem = (item) => {
        if (history.length === size) {
            history.shift();
        }
        history.push(item);
        publish(item);
    }

    this.updateItem = (item) => {
        let index = history.findIndex(i => i.id === item.id);
        if (index < 0) {
            throw new Error(`Cannot find item with id ${item.id}`)
        }
        history[index] = { ...history[index], ...item };
        publish(item);
    }

    this.featureItems = () => {
        let featureItems = [];
        let filtered = history.filter(i => [TYPE.ALEXA, TYPE.HUMAN].includes(i.type));

        let pair = {};
        for (const i of filtered) {
            const content = i.content.replace(/"/g, '');
            // Push an entry for an answer
            if (i.type === TYPE.ALEXA) {
                pair["A"] = content;
                pair["Q"] && pair["A"] && featureItems.push({...pair});
                pair = {};
            }
            // Up to last Human for the Answer
            if (i.type === TYPE.HUMAN) {
                pair["Q"] = content;
            }
        }

        return featureItems;
    }

    this.clear = async () => {
        history = [];
    }

    this.subscribe = (callback, filter = i => true) => {
        subscribers.push({callback, filter});

        // Return unsubscribe
        return () => {
            const index = _.findIndex(subscribers, i => i.callback == callback);
            if (index !== -1) {
                subscribers.splice(index, 1);
            }
        };
    };
}
