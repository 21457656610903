export const DEVICE_CATEGORY = {
  LIGHTING: 'Lighting'
}

export const TEST_PLAN_NAME = {
  POWER_CONTROLLER: 'PowerController',
  BRIGHTNESS_CONTROLLER: 'BrightnessController',
  COLOR_CONTROLLER: 'ColorController',
  COLOR_TEMPERATURE_CONTROLLER: 'ColorTemperatureController',
  ENDPOINT_CONTROLLER: 'EndpointManagement',
  MOBILE_CONTROLLER: 'Mobile',
  APP2APP3P_CONTROLLER: 'AppToApp3p',
  LAB_SETUP: 'Lab and Device setup Verification'
}

export const CATEGORY_TEST_PLAN_MAP = {
  [DEVICE_CATEGORY.LIGHTING]: [
    {
      scenario: TEST_PLAN_NAME.POWER_CONTROLLER,
      DUT: 1,
      scenarioId: "@power",
    },
    {
      scenario: TEST_PLAN_NAME.BRIGHTNESS_CONTROLLER,
      DUT: 1,
      scenarioId: "@brightness",
    },
    {
      scenario: TEST_PLAN_NAME.COLOR_CONTROLLER,
      DUT: 1,
      scenarioId: "@color",
    },
    {
      scenario: TEST_PLAN_NAME.COLOR_TEMPERATURE_CONTROLLER,
      DUT: 1,
      scenarioId: "@colortemperature",
    },
    {
      scenario: TEST_PLAN_NAME.ENDPOINT_CONTROLLER,
      DUT: 1,
      scenarioId: "@endpoint",
    },
    {
      scenario: TEST_PLAN_NAME.APP2APP3P_CONTROLLER,
      DUT: 1,
      scenarioId: "@app2app3p",
    },
    {
      scenario: TEST_PLAN_NAME.MOBILE_CONTROLLER,
      DUT: 1,
      scenarioId: "@mobile",
    },
    {
      scenario: TEST_PLAN_NAME.LAB_SETUP,
      DUT: 1,
      scenarioId: "@lab-setup",
    }
  ]
}

export const TEST_PLAN_TAG_MAP = {
  "@lab-setup": {
    scenario: TEST_PLAN_NAME.LAB_SETUP
  },
  "@power": {
    scenario: TEST_PLAN_NAME.POWER_CONTROLLER
  },
  "@brightness": {
    scenario: TEST_PLAN_NAME.BRIGHTNESS_CONTROLLER
  },
  "@color": {
    scenario: TEST_PLAN_NAME.COLOR_CONTROLLER
  },
  "@colortemperature": {
    scenario: TEST_PLAN_NAME.COLOR_TEMPERATURE_CONTROLLER
  },
  "@endpoint": {
    scenario: TEST_PLAN_NAME.ENDPOINT_CONTROLLER
  },
  "@mobile": {
    scenario: TEST_PLAN_NAME.MOBILE_CONTROLLER
  },
  "@app2app3p": {
    scenario: TEST_PLAN_NAME.APP2APP3P_CONTROLLER
  }
}

// Uncomment below once device attributes tag is supported in backend
// export const DEVICE_ATTRIBUTES_TAG_MAP = {
//   "irBlasters": "@ir-blaster-na",
//   "dimmable": "@dimmable"
// }
