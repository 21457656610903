import axios from 'axios';
import openSocket from 'socket.io-client';
import ApiConstants from '../../_Constants/ApiConstants';
import AppConstants from '../../_Constants/AppConstants';
import FunctionalTestCases from '../../_Constants/FunctionalTestCasesConstants';
import { withHeader } from '../../Auth';
import { setLiveFeed } from '../../Components/State/Actions';
import { aqtStore, liveFeedStore } from '../../Components/State/Store';
import { networkError, getSession, countAndPercentage, getPercentage, getTime,
  getParsedJSON, logToConsole } from '../../Util';
import { has } from 'lodash';
import AutoLocalSearchTestConstants from '../../_Constants/AutoLocalSearchTestConstants';
import UPLTestData from '../../Test/UPLTestData';
import moment from 'moment';
import TestData from '../../Test/TestData';
import ld from 'lodash';
import MobileFunctionalTestData from "../../Test/MobileFunctionalTestData";
import {MQTT_TIMEOUT} from "../../_Constants/AQTHotspotConstants";
import {convertStrToJson} from "../Labs/controller";

export function fetchRunningJobs() {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  let query = {
      'state': {'notIn': ['SUCCEEDED', 'FAILED', 'CANCELED']},
      'createdAt': {'gte': moment().subtract(1, 'month').startOf('day'), "lte": moment().endOf('day')}
  };
  return axios.get(controllerEndpoint +
      ApiConstants.POST_JOB_SUBMIT_URL +
      '?q=' +
      JSON.stringify(query) + '&limit=50',
      withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(labJobs => {
      if (labJobs.hasOwnProperty('data')) {
        logToConsole('Lab Jobs : ' + JSON.stringify(labJobs.data));
        labJobs.data.forEach(labJob => {

          let liveFeed = {};
          const jobSocket = openSocket(controllerEndpoint, {
            query: {
              token: aqtStore.getState().session.idToken.jwtToken,
              instanceID: labJob.id
            }
          });

          let options = JSON.parse(labJob.options);
          let mapping = JSON.parse(labJob.mapping);
          let testStats = {};

          if (mapping && options) {
            if (AppConstants.FAR_TEST_TYPES.includes(options.testType)) {
              testStats = {
                startTime: new Date(labJob.createdAt).toString(),
                testType: options.testType === AppConstants.FAR ? AppConstants.FAR : AppConstants.FAR_CUSTOM,
                labJobId: labJob.id
              };
            }
          }

          liveFeed[labJob.id] = { labJob: labJob,
                                  socketClose: () => { jobSocket.close() },
                                  testStatus: AppConstants.RETRIEVING,
                                  testStats: testStats,
                                  visualStats: {},
                                  simulationState: {},
                                  isSocketConnected: false,
                                  error: { isError: false, message: '' } };

          liveFeed[labJob.id]['request'] = labJob
          liveFeedStore.dispatch(setLiveFeed(AppConstants.LIVEFEED, liveFeed));
          setSocketHandlers(labJob.id, jobSocket);
        });
        return labJobs.data;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      networkError(error, fetchRunningJobs.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

/**
 * Gets ref mic status for Functional and Auto local search scenarios
 */
function getRefMicStatus(payload) {
  // Read refMicStatus from payload if available
  if (payload.refMicStatus) {
    return payload.refMicStatus;
  }
  // Read referenceMicConnectionStatus from payload if refMicStatus is not available.
  if (payload.referenceMicConnectionStatus) {
    return payload.referenceMicConnectionStatus;
  }
  // Return retrieving by default
  return FunctionalTestCases.REF_MIC_STATUS_UNAVAILABLE;
}

/**
 * Gets device virtual assistant
 */
function getDeviceVirtualAssistant(simulationOptions) {
  let deviceVirtualAssistant = AutoLocalSearchTestConstants.ALEXA;
  // Retrieve device virtual assistant for Auto local search scenario
  if (simulationOptions && simulationOptions.hasOwnProperty('deviceAssistantType')) {
    deviceVirtualAssistant = simulationOptions.deviceAssistantType;
  }
  return deviceVirtualAssistant;
}

export function setLiveFeedData(labJobId, key, value) {
  let liveFeed = liveFeedStore.getState().liveFeed;
  let overrideFlag = false;
  try {
    logToConsole('Parsing key: ' + key + ' for labJobId: ' + labJobId + ' with value: ' + value);
    switch (key) {
      case 'commonFeeds':
        // currently utilized by morgan to publish utterance info
        let parsedCommonFeeds = getParsedJSON(value);
        let commonFeeds = parsedCommonFeeds.hasOwnProperty('payload') ? getParsedJSON(parsedCommonFeeds.payload) : {};
        liveFeed[labJobId].commonFeeds = {
          payload: commonFeeds
        }
        break;
      case 'utteranceFeeds':
        // currently utilized by morgan to publish utterance info
        let parsedUtteranceFeeds = getParsedJSON(value);
        let utteranceFeeds = parsedUtteranceFeeds.hasOwnProperty('payload') ? getParsedJSON(parsedUtteranceFeeds.payload) : {};
        liveFeed[labJobId].utteranceFeeds = {
          payload: utteranceFeeds
        }
        break;
      case 'noiseFeeds':
        // currently utilized by morgan to publish noise info
        let parsedNoiseFeeds = getParsedJSON(value);
        let noiseFeeds = parsedNoiseFeeds.hasOwnProperty('payload') ? getParsedJSON(parsedNoiseFeeds.payload) : {};
        liveFeed[labJobId].noiseFeeds = {
          payload: noiseFeeds
        }
        break;
      case 'payload':
        logToConsole('Original value before stringify = ' + value);
        // Apply parsing on JSON only if it contains back slash
        let parsedPayload = getParsedJSON(value);
        logToConsole('Parsed payload = ' + JSON.stringify(parsedPayload));
        let payload = parsedPayload.hasOwnProperty('payload') ? getParsedJSON(parsedPayload.payload) : {};
        // 'FunctionalTestCaseResults' in Mobile Functional payload (based on AQTEngine format) has the same structure as
        // 'customPayload' (based on MDXSimulator format)
        let customPayload = payload.hasOwnProperty('functionalTestCaseResults') ?
          getParsedJSON(payload.functionalTestCaseResults) : parsedPayload.hasOwnProperty('customPayload') ?
            getParsedJSON(parsedPayload.customPayload) : {};
        // 'FunctionalTestCase' in Mobile Functional payload (based on AQTEngine format) has the same structure as
        // 'payload' (based on MDXSimulator format)
        payload = payload.hasOwnProperty('functionalTestCase') ? getParsedJSON(payload.functionalTestCase) : payload;
        let transcripts = customPayload.hasOwnProperty('transcripts') ? customPayload.transcripts : {};
        let simulationOptions = customPayload.hasOwnProperty('simulationOptions') ? customPayload.simulationOptions : {};
        // Retrieve device virtual assistant
        let deviceVirtualAssistant = getDeviceVirtualAssistant(simulationOptions);

        // Only for functional test cases
        // Retrieve list of all functional tests + map which contains status at category level for each test category
        // Also retrieve 'Question Played' from payload in case of Functional scenario
        let functionalTestCases = customPayload.hasOwnProperty('functionalTestCases') ? customPayload.functionalTestCases : {};
        let testCaseCategoryStatusMap = customPayload.hasOwnProperty('testCaseCategoryStatusMap') ? customPayload.testCaseCategoryStatusMap : {};
        let questionPlayed = (customPayload.scenarioType === FunctionalTestCases.FUNC_ALL
          || customPayload.scenarioType === FunctionalTestCases.FUNC_CUSTOM
          || customPayload.scenarioType === FunctionalTestCases.AUTO_FUNC_ALL
          || customPayload.scenarioType === FunctionalTestCases.AUTO_FUNC_CUSTOM
          || customPayload.scenarioType === FunctionalTestCases.FUNC_NAV_ALL
          || customPayload.scenarioType === FunctionalTestCases.FUNC_NAV_CUSTOM)
          ? payload.transcript : transcripts.asrText;
        let mobileFunctionalCondition = customPayload.scenarioType === AppConstants.MOBILE_FUNCTIONAL
          && payload.currentUtterance;
        questionPlayed = (mobileFunctionalCondition) ? payload.currentUtterance.questionPlayed : questionPlayed;

        let statusFromPayload = payload.status ? payload.status.toLowerCase() : 'error';
        let functionalUtteranceStatus = payload.status ? payload.status : FunctionalTestCases.FAILED;
        let intentTypeFromPayload = payload.intentType ? payload.intentType : AppConstants.EMPTY;
        let utteranceAttribute = payload.utteranceAttribute ? payload.utteranceAttribute : FunctionalTestCases.UTTERANCE_NORMAL;

        // Retrieve reference mic status from custom payload for Functional and Auto local search scenarios
        let refMicStatus = getRefMicStatus(payload);

        // Retrieve Utterance validation type for Functional scenario
        let utterValidationInfo = payload.utterValidationInfo ? payload.utterValidationInfo : {};
        let utteranceValidationType = AppConstants.EMPTY;
        if (utterValidationInfo
              && Object.keys(utterValidationInfo).length > 0
              && utterValidationInfo.hasOwnProperty('utterValidationType')) {
                utteranceValidationType = utterValidationInfo['utterValidationType'];
        }

        let frr;
        let iar;
        let curNumFrr = customPayload.curNum;
        try {
          if (customPayload.scenarioType === AppConstants.TRAINED_MOBILE) {
            curNumFrr = customPayload.curNumFrr;
          }
          if (!ld.isUndefined(payload.frr) && curNumFrr) {
            frr = parseInt(curNumFrr, 10) - parseInt(payload.frr, 10)
          }
          if (payload.iar && customPayload.curNumIar) {
            iar = parseInt(customPayload.curNumIar, 10) - parseInt(payload.iar, 10)
          }
        } catch (error) {
          logToConsole('Parse error : ' + JSON.stringify(error));
        }
        let measureType = customPayload.measureType;
        let locationNoise = AppConstants.NOT_APPLICABLE;
        if (measureType !== AppConstants.ADMIN_MEASURE) {
          locationNoise = customPayload.testActor + ', ' +customPayload.noiseType;
        }

        // Mobile param for active noise (stripped down version of locationNoise due to Mobile only having 1 location)
        let activeNoise = customPayload.hasOwnProperty('noiseType') ? customPayload.noiseType : AppConstants.EMPTY;

        // Check if test suite is AUTO_LOCAL_SEARCH
        if (simulationOptions
            && simulationOptions.hasOwnProperty('testSuite')
            && simulationOptions.testSuite === AppConstants.AUTO_LOCAL_SEARCH_SUITE_ID) {
              liveFeed[labJobId].testStats = {
                ...liveFeed[labJobId].testStats,
                utteranceId: payload.utteranceId,
                count: countAndPercentage(payload.utteranceId, payload.totalTests),
                percentage: getPercentage(payload.utteranceId, payload.totalTests),
                elapsedTime: getTime(new Date().getTime() - customPayload.startTime),
                startTime: new Date(customPayload.startTime).toString(),
                eta: payload.eta,
                refMicStatus: refMicStatus,
                status: payload.status,
                transcribeStatus: payload.transcribeStatus,
                played: payload.utterancePlayedTranscript,
                heard: payload.dutHeardAsrText,
                responded: payload.dutResponse,
                locationNoise: AppConstants.LOCATION_THREE_NINETY,
                testCases: customPayload.testCases,
                deviceVirtualAssistant: deviceVirtualAssistant
              };
        } else if (parsedPayload && simulationOptions.testSuite === AppConstants.SECURITY_SCENARIO_ID) {
          liveFeed[labJobId].testStats = {
            payload: parsedPayload
          }
        } else if (parsedPayload && simulationOptions.testSuite === AppConstants.CUSTOM_SCENARIO_ID) {
          liveFeed[labJobId].testStats = parsedPayload.payload;
        } else if (parsedPayload
            && parsedPayload.androidDeviceIsConnected !== undefined) {
          // For automated Mobile Voice Training scenario, every time training begins, UI should receive
          // following key in payload. Any other regular payloads should not contain this key
          // Key: androidDeviceIsConnected --> Whether android device is connected to Pi & online or not
          liveFeed[labJobId].testStats = {
              androidDeviceIsConnected: parsedPayload.androidDeviceIsConnected
          }
        } else if (simulationOptions
          && simulationOptions.hasOwnProperty('testType')
          && (AppConstants.FAR_TEST_TYPES.includes(simulationOptions.testType) ||
              AppConstants.STABILITY_TEST_TYPES.includes(simulationOptions.testType) ||
              AppConstants.UPL_TEST_TYPES.includes(simulationOptions.testType))) {
            // Set payload for NEW Payload format that contains everything to display on liverun page.
            liveFeed[labJobId].testStats = {
              payload: payload
            };
            liveFeed[labJobId].testStatus = AppConstants.PROCESSING;
        } else {
        // Display test metrics only if measureType is NOT ADMIN_NO_RECORD
        // TODO: Display informational message on live run page when admin utterances are being played
        // SIM: https://sim.amazon.com/issues/P22697831
        if (!AppConstants.FAR_TEST_TYPES.includes(customPayload.testType)) {
          if (measureType !== AppConstants.ADMIN_MEASURE) {
            liveFeed[labJobId].testStats = {
              ...liveFeed[labJobId].testStats,
              num: customPayload.curNum,
              played: questionPlayed,
              heard: (mobileFunctionalCondition) ? payload.currentUtterance.dutHeard : payload.asrText ,
              responded: (mobileFunctionalCondition) ? payload.currentUtterance.dutResponded :payload.ttsResponse,
              count: countAndPercentage(customPayload.curNum, customPayload.totalNum),
              percentage: getPercentage(customPayload.curNum, customPayload.totalNum),
              frr: countAndPercentage(frr, curNumFrr),
              rar: countAndPercentage(payload.rar, payload.frr),
              har: countAndPercentage(payload.har, customPayload.curNum),
              iar: countAndPercentage(iar, customPayload.curNumIar),
              scoreFrr: payload.scoreFalseRejectionRate,
              scoreRar: payload.scoreResponseAccuracyRate,
              scoreHar: payload.scoreHearingAccuracyRate,
              scoreIar: payload.scoreImposterAcceptanceRate,
              status: statusFromPayload === 'success' ? 'success' : 'error',
              timeRemaining: customPayload.etaString,
              elapsedTime: getTime(new Date().getTime() - customPayload.startTime),
              startTime: new Date(customPayload.startTime).toString(),
              testType: customPayload.testType,
              avgTestTime: payload.avgTestTime,
              scenarioType: customPayload.scenarioType,
              labJobId: labJobId,
              locationNoise: locationNoise,
              activeNoise: activeNoise,
              measureType: transcripts.measureType,
              currentTrainedVoice: customPayload.currentTrainedVoice,
              measureTypeCustomPayload: customPayload.measureType,
              currentUtteranceCount: customPayload.curNum,
              latencyValue: customPayload.latencyValue,
              functionalTestCases: functionalTestCases,
              testCaseCategoryStatusMap: testCaseCategoryStatusMap,
              currentFunctionalTest: payload.testCase,
              utteranceValidationType: utteranceValidationType,
              functionalUtteranceStatus: functionalUtteranceStatus,
              utteranceAttribute: utteranceAttribute,
              refMicStatus: refMicStatus,
              isProxyEnabled: payload.hasOwnProperty('isProxyEnabled') ? payload.isProxyEnabled : AppConstants.UNKNOWN,
              is3PDAEnabled: payload.hasOwnProperty('is3PDAEnabled') ? payload.is3PDAEnabled : AppConstants.UNKNOWN
            };
          } else {
            liveFeed[labJobId].testStats = {
              measureTypeCustomPayload: measureType,
              intentType: intentTypeFromPayload
            };
          }
         }
        }

        // Check for payload override cases - Keep this at the bottom in case other scenarios want to
        //    utilize some of the above info for overriding values
        if (AppConstants.OVERRIDE_LIVE_RUN_PAYLOAD_TEST_TYPES.includes(customPayload.scenarioType)) {
          // Handler for AC_VOICE_ENROLLMENT_ROBUSTNESS override
          if (customPayload.scenarioType == AppConstants.VOICE_ROBUSTNESS) {
            // VE Robustness relies exclusively on the enrollmentFeed - No need to adjust params (just override)
            overrideFlag = true;
          }
        }
        break;
      case 'testStatus':
        logToConsole(value);
        let parsedTestStatus = JSON.parse(value);
        liveFeed[labJobId].testStatus = parsedTestStatus.status;
        if (parsedTestStatus.status.toLowerCase() === 'completed') {
          liveFeed[labJobId].testStats = {
            ...liveFeed[labJobId].testStats,
            timeRemaining: 'Finished'
          };
        }
        break;
      case 'fan':
      case '1.8 m 135 deg':
        let parsedNoiseStatus = JSON.parse(value);
        liveFeed[labJobId].visualStats = {
          ...liveFeed[labJobId].visualStats,
          noiseState: parsedNoiseStatus.state
        }
        liveFeed[labJobId].simulationState[key] = parsedNoiseStatus;
        break;
      case 'DUT':
        liveFeed[labJobId].simulationState[key] = JSON.parse(value);
        break;
      case 'speakNineNinety':
      case 'speakNineThirty':
      case 'speakThreeNinety':
      case 'speakThreeThirty':
      case '2.7 m 90 deg':
      case '2.7 m 30 deg':
      case '0.9 m 90 deg':
      case '0.9 m 30 deg':
      case 'Automotive Companion':
      case 'automotiveCompanion':
        let parsedPayloadValue = JSON.parse(value);
        liveFeed[labJobId].simulationState[key] = parsedPayloadValue;
        liveFeed[labJobId].visualStats = setSpeakerState(liveFeed[labJobId].visualStats, parsedPayloadValue);
        break;
      case 'enrollmentFeed':
          // Standardize input format
          let cleanedValue = JSON.stringify(value).replace(/\\*\"/g,'"').replace(/\"\{/g,'{').replace(/\}\"/g,'}');
          let enrollmentFeedPayload = has(getParsedJSON(cleanedValue), 'payload') ? getParsedJSON(cleanedValue).payload : AppConstants.EMPTY;

          // Populate enrollmentFeed values with voice enrollment metrics
          let enrollmentFeed = {};
          if (enrollmentFeedPayload) {
            if (has(enrollmentFeedPayload, 'veRobProgress')) {
              let veRobProgress = enrollmentFeedPayload.veRobProgress;
              enrollmentFeed.currentTrainedVoice = has(veRobProgress, 'currentTrainedVoice') ? veRobProgress.currentTrainedVoice : AppConstants.EMPTY;
              enrollmentFeed.activeNoise = has(veRobProgress, 'activeNoise') ? veRobProgress.activeNoise : AppConstants.EMPTY;
              enrollmentFeed.totalNum = has(veRobProgress, 'totalNum') ? veRobProgress.totalNum : [];
              enrollmentFeed.curNum = has(veRobProgress, 'curNum') ? veRobProgress.curNum : [];
              let testStartTime = has(veRobProgress, 'testStartTime') ? veRobProgress.testStartTime : [];
              if (testStartTime) {
                enrollmentFeed.startTime = new Date(testStartTime).toString();
                enrollmentFeed.elapsedTime = getTime(new Date().getTime() - testStartTime);
              }
            }
            enrollmentFeed.uiAutomationStatus = has(enrollmentFeedPayload, 'uiAutomationStatus') ?
                                                      enrollmentFeedPayload.uiAutomationStatus : AppConstants.EMPTY;
            // Calculations for test progress
            enrollmentFeed.completedCount = enrollmentFeed.curNum;
            // If current training iteration isn't complete (doesn't have uiAutomationStatus), subtract 1 to reflect
            //    only the completed count
            if (enrollmentFeed.uiAutomationStatus === AppConstants.EMPTY) {
              enrollmentFeed.completedCount = enrollmentFeed.curNum > 0 ? enrollmentFeed.curNum - 1 : 0;
            }
            enrollmentFeed.completedPercentage = (enrollmentFeed.completedCount && enrollmentFeed.totalNum) ?
              Math.round((enrollmentFeed.completedCount / enrollmentFeed.totalNum) * 100) : [];
          }

        liveFeed[labJobId].testStats = {
          enrollmentFeed: enrollmentFeed
        };
        break;
      default:
        liveFeed[labJobId].simulationState[key] = JSON.parse(value);
        break;

    }
  } catch (ex) {
    logToConsole('Error in parsing payload from server : ' + ex);
  }
  if (!overrideFlag) {
    liveFeedStore.dispatch(setLiveFeed(AppConstants.LIVEFEED, liveFeed));
  }
}

export function setSocketHandlers(labJobId, jobSocket) {
  // sample setup for mocking live run data
  //setLiveFeedData(labJobId, 'utteranceFeeds', TestData.AUTOMOTIVE_UTTERANCE_FEED);
  //setLiveFeedData(labJobId, 'noiseFeeds', TestData.AUTOMOTIVE_NOISE_FEED);
  //setLiveFeedData(labJobId, 'commonFeeds', TestData.AUTOMOTIVE_COMMON_FEED);
  //setLiveFeedData(labJobId, 'payload', MobileFunctionalTestData.TEST_DATA);
  //setLiveFeedData(labJobId, 'testStatus', "{ \"status\": \"RUN\" }");
  let keys = {}
  AppConstants.keys.forEach(key => {
    keys[key] = false;
  });
  jobSocket.on('connect', () => {
    logToConsole('Socket opened for job : ' + labJobId);
    let liveFeeds = liveFeedStore.getState().liveFeed;
    liveFeeds[labJobId].isSocketConnected = true;
    liveFeeds[labJobId].error = { isError: false, message: '' };
    liveFeedStore.dispatch(setLiveFeed(AppConstants.LIVEFEED, liveFeeds));
  });
  jobSocket.on('connect_failed', error => {
    logToConsole('Socket connection failed for job : ' + labJobId + ' - ' + error);
    let liveFeeds = liveFeedStore.getState().liveFeed;
    liveFeeds[labJobId].isSocketConnected = false;
    liveFeeds[labJobId].error = { isError: true, message: 'Connection to Job failed!' };
    liveFeedStore.dispatch(setLiveFeed(AppConstants.LIVEFEED, liveFeeds));
  });
  jobSocket.on('error', error => {
    logToConsole('Socket error from server for job : ' + labJobId + ' - ' + error);
    let liveFeeds = liveFeedStore.getState().liveFeed;
    liveFeeds[labJobId].isSocketConnected = false;
    liveFeeds[labJobId].error = { isError: true, message: 'Error in connection to Job!' };
    liveFeedStore.dispatch(setLiveFeed(AppConstants.LIVEFEED, liveFeeds));
  });
  jobSocket.on('stateChange', data => {
    try {
      let currentKey = AppConstants.keys[0];
      JSON.parse(JSON.stringify(data), (key, value) => {
        if (keys[currentKey]) {
          // comment out the below when u try to mock live run
          setLiveFeedData(labJobId, currentKey, value);
          keys[currentKey] = false;
        }
        else if (key === 'key' && typeof value === 'string') {
          currentKey = value.split(':').slice(-1)[0];
          keys[currentKey] = true;
        }
      });
    } catch (ex) {
      logToConsole('Error in parsing keys from server : ' + JSON.stringify(ex));
    }
    // v2 logic to get entire redis data for the job
    let liveFeeds = liveFeedStore.getState().liveFeed;
    const dataKey = data.key.split(":").slice(-1)[0];
    let dataValue = data.data;
    try {
      dataValue = JSON.parse(dataValue);
    } catch (error) {
      logToConsole("error parsing redis key data", error);
    }
    const liveFeedsJobData = ld.get(liveFeeds, `${labJobId}.v2data`, {});
    liveFeeds[labJobId]["v2data"] = {
      ...liveFeedsJobData,
      [dataKey]: dataValue,
    };
    liveFeedStore.dispatch(setLiveFeed(AppConstants.LIVEFEED, liveFeeds));
  });
  jobSocket.on('disconnect', () => {
    logToConsole('Socket closed for job : ' + labJobId);
    let liveFeeds = liveFeedStore.getState().liveFeed;
    liveFeeds[labJobId].isSocketConnected = false;
    liveFeeds[labJobId].error = { isError: false, message: '' };
    liveFeedStore.dispatch(setLiveFeed(AppConstants.LIVEFEED, liveFeeds));
  });
}

export function terminateJob(labJobId) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  // Closing the job socket
  if (liveFeedStore.getState().liveFeed && liveFeedStore.getState().liveFeed[labJobId]) {
    liveFeedStore.getState().liveFeed[labJobId].socketClose();
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  let body = {
    state: 'CANCELED'
  };
  return axios.put(controllerEndpoint +
      ApiConstants.POST_JOB_SUBMIT_URL +
      '/' + labJobId,
      body,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      if (response.status === 200) {
        return {
          success: response.statusText
        }
      } else {
        return {
          error: response.statusText
        };
      }
    })
    .catch(error => {
      networkError(error, terminateJob.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

/**
 * Resumes the paused job for Trained mobile scenario
 * @param labJobId Job ID
 */
export function resumeJob(labJobId) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  let body = {
    state: AppConstants.RESUME
  };
  return axios.put(controllerEndpoint +
      ApiConstants.POST_JOB_SUBMIT_URL +
      '/' + labJobId,
      body,
      withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      if (response.status === 200) {
        return {
          success: response.statusText
        }
      } else {
        return {
          error: response.statusText
        };
      }
    })
    .catch(error => {
      networkError(error, resumeJob.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

/**
 * Get Lab information from the job ID. This is needed to for raspi sync status in live run.
 * @param labJobId Job ID
 */
export function getLabInformation(labJobId) {
  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }
  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  return axios.get(controllerEndpoint +
      ApiConstants.POST_JOB_SUBMIT_URL +
      '/' + labJobId + '/lab',
      withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(labInfo => {
      logToConsole('Lab Info : ' + JSON.stringify(labInfo));
      if (labInfo.data.hasOwnProperty('id')) {
        return labInfo.data;
      } else {
        return {
          error: AppConstants.NOLABS
        }
      }
    })
    .catch(error => {
      networkError(error, resumeJob.name);
      return {
        error: AppConstants.NETWORKERR
      };
    });
}

//Returns a new payload if the playing state is not Idle
export function setSpeakerState(currPayload, parsedSpeakerPayload) {
  return parsedSpeakerPayload.state !== AppConstants.IDLE ?
    Object.assign({}, currPayload,
      { speakerState: {
          speaker: parsedSpeakerPayload.name,
          state: parsedSpeakerPayload.state
      }}
    ) : currPayload;
}


/**
 * publish MQTT topic
 *
 * @param {string} jobId
 * @param {string} messageId
 * @param {object} payload
 */
export function sendSimpleMQTTMessage(jobId, messageId, payload, timeout = MQTT_TIMEOUT, topicOverride = '') {

  let mqttPayload;
  if (topicOverride) {
    mqttPayload = {
      responseTopic: topicOverride + "/" + jobId + "/" + messageId + "/response",
      requestTopic: topicOverride + "/" + jobId + "/" + messageId + "/request",
      payload,
    }
  } else {
    mqttPayload = {
      responseTopic: jobId + "/" + messageId + "/response",
      requestTopic: jobId + "/" + messageId + "/response",
      payload,
    };
  }

  logToConsole("Sending MQTT message: ", mqttPayload);

  if (getSession() === AppConstants.SESSIONERR) {
    return new Promise(resolve => {
      resolve({
        error: AppConstants.SESSIONERR
      })
    });
  }

  let controllerEndpoint = aqtStore.getState().environment.controllerEndpoint;
  return axios.post(controllerEndpoint +
    ApiConstants.SEND_MQTT_MESSAGE +
    '?waitForActionTime=' + timeout,
    mqttPayload,
    withHeader(aqtStore.getState().session.idToken.jwtToken))
    .then(response => {
      logToConsole("Response from MQTT: ", response);
      return response;
    })
    .catch(error => {
      logToConsole("Error from MQTT: ", error);
      return error.response.data;
    });

}

/**
 * Perfroms controller api call send MQTT message
 * invoke plugin actions
 *
 * @param {string} jobId
 * @param {string} messageId
 * @param {string} payload
 */
export function publishMessage(jobId, messageId, payload, timeout = 5000, topicOverride = '') {
  return sendSimpleMQTTMessage(jobId, messageId, payload, timeout, topicOverride)
    .then(response => {
      if (response.error) {
        let payload = convertStrToJson(response.error);
        if (payload.state == 'failed') {
          return new Promise(resolve => { resolve(false) });
        }
      } else {
        try {
          const actualResponse = convertStrToJson(response.data);
          if (response.code == 200) {
            return new Promise(resolve => { resolve(true) });
          }
        } catch (err) {
          logToConsole('Exception caught: ', err)
        } finally {
          return new Promise(resolve => { resolve(false) });
        }
      }
    });
}
