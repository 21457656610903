import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { Box, Button, Container, Grid, Header, Link, Spinner, SpaceBetween, FormField, Input }
  from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import { Auth } from 'aws-amplify';
import { AuthPiece } from 'aws-amplify-react';
import AppConstants from '../../_Constants/AppConstants';
import { logToConsole } from '../../Util';
import {aqtStore} from "../../Components/State/Store";

const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

class Login extends AuthPiece {

  state = {
    username: AppConstants.EMPTY,
    password: AppConstants.EMPTY,
    error: window.location.href.includes(AppConstants.SESSION_EXPIRED_PARAM)
      ? AppConstants.SESSIONERR : AppConstants.EMPTY,
    signingIn: false,
    showPassword: false
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.detail.value, error: AppConstants.EMPTY });
  }

  handleForgotPassword = () => {
    this.props.forgotpassword({});
  }

  handleSignIn = (event) => {
    // Authentication
    this.setState({ signingIn: true  });
    Auth.signIn(this.state.username, this.state.password)
        .then(user => {
          let userString = JSON.stringify(user);
          this.setState({ signingIn: false, error: AppConstants.EMPTY });
          this.changeState('signedIn');

          // remove sessionExpired from query params
          this.props.history.push({ search: '' });

          Auth.currentSession()
              .then( session => {
                this.props.authenticate(session);
              })
              .catch( err => {
                var message;
                if (typeof err === 'string') {
                  message = err;
                } else {
                  message = err.message;
                }
                logToConsole('Error establishing session' + message);
                if (userString.includes(NEW_PASSWORD_REQUIRED)) {
                  this.props.authenticate(user);
                } else {
                  this.props.authenticate({});
                }
            });
        })
        .catch(err => {
          var message;
          if (typeof err === 'string') {
            message = err;
          } else {
            message = err.message;
          }
          this.setState({ signingIn: false, error: message });
          this.error(err);
          logToConsole(err);
        });
  }

  handleFederatedSignIn = (event) => {
    let env = aqtStore.getState().environment;
    const params = new URLSearchParams();
    params.append('identity_provider', 'LoginWithAmazon');
    params.append('redirect_uri', env.oauth.redirectSignIn);
    params.append('response_type', 'CODE');
    params.append('client_id', env.aws_user_pools_web_client_id);
    params.append('scope', 'email openid profile');
    window.location.href=`https://${env.oauth.domain}/oauth2/authorize?${params.toString()}`;
  }

  notice() {
    return (
      <React.Fragment>
        <Box textAlign="center">
          <span>By continuing, you agree to </span>
          <Link external href="https://www.amazon.com/gp/help/customer/display.html/ref=ap_signin_notification_condition_of_use?ie=UTF8&nodeId=508088">
            Conditions of Use
          </Link>
          <span> and </span>
          <Link external href="https://www.amazon.com/gp/help/customer/display.html/ref=ap_signin_notification_privacy_notice?ie=UTF8&nodeId=468496">
            Privacy Notice
          </Link>
        </Box>
      </React.Fragment>
    );
  }

  passLabelAndForgotLink() {
    return (
      <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
        <p><b>Password</b></p>
        <Link
          onFollow={event => {
            event.preventDefault();
            this.handleForgotPassword();
          }}
        >
          <p className="awsui-util-f-r">Forgot password?</p>
        </Link>
      </Grid>
    );
  }

  loginForm() {
    return (
      <SpaceBetween direction="vertical" size="m">
        <FormField label={<b>Email</b>}>
          <Input
            disableBrowserAutocorrect
            type="email"
            value={this.state.username}
            onChange={this.handleChange('username')}
          />
        </FormField>
        <FormField label={this.passLabelAndForgotLink()}>
          <Input
            disableBrowserAutocorrect
            type="password"
            value={this.state.password}
            onChange={this.handleChange('password')}
          />
        </FormField>
        <Button fullWidth
          variant="primary"
          onClick={this.handleSignIn}>
          Sign in
        </Button>
        <Button fullWidth
          variant="primary"
          onClick={this.handleFederatedSignIn}>
          Login With Amazon
        </Button>
        <FormField errorText={this.state.error}/>
      </SpaceBetween>
    )
  }

  render() {
    return(
      <Grid gridDefinition={[{ colspan: 12 }, { colspan: 12 }]}>
        <div style={{ height: "10vh" }}></div>
        { this.state.signingIn ? (
            <div className="awsui-util-t-c"><Spinner size="large" /></div>
        ) : (
            <div style={{ width: "400px", margin: "0 auto" }}>
                <SpaceBetween direction="vertical" size="xl">
                  <Box textAlign="center" variant="h1">Open Automation Kit</Box>
                  <Container footer={this.notice()}>
                    <SpaceBetween direction="vertical" size="m">
                      <Header variant="h1">Sign in</Header>
                      { this.loginForm() }
                    </SpaceBetween>
                  </Container>
                    <div className="awsui-util-t-c"><img width="120px" src="/Resources/amazon_logo_RGB.png" /></div>
                </SpaceBetween>
            </div>
        )}
      </Grid>
    )
  }
}

Login.propTypes = {
  authenticate: PropTypes.func.isRequired,
  forgotpassword: PropTypes.func.isRequired
};

export default withRouter(Login);
