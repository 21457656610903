import { split, join } from 'lodash';
import { logToConsole } from '../../Util';
import AppConstants from '../../_Constants/AppConstants';

export const AMEND_CMD = `awk '
/ExecStart/ {
  print "ExecStart=/usr/bin/sudo PYENV_ROOT=$PYENV_ROOT PYENV_VERSION=$PYENV_VERSION PYTHONUSERBASE=$PYENV_ROOT/versions/$PYENV_VERSION PIP3_PATH=$PIP3_PATH env \\\"LD_LIBRARY_PATH=/usr/local/MATLAB/MATLAB_Runtime/v96/runtime/glnxa64:/usr/local/MATLAB/MATLAB_Runtime/v96/bin/glnxa64:/usr/local/MATLAB/MATLAB_Runtime/v96/sys/os/glnxa64:/usr/local/MATLAB/MATLAB_Runtime/v96/extern/bin/glnxa64\\\" $PYENV_ROOT/versions/$PYENV_VERSION/bin/python3 -m mdx.main"
  next
}
/pyenv install/ {
  print "  execute_with_retry $RETRIES env PYTHON_CONFIGURE_OPTS=\\\"--enable-shared\\\" $PYENV_ROOT/bin/pyenv install $PYENV_VERSION || error_exit \\\"failed to install Python $PYENV_VERSION\\\""
  next
}
{ print $0 } '`

export const UPDATE_PYTHON_VERSION_CMD = `awk '
/log_progress "Setting up managed Python version..."/ {
print $0;
print "[[ -f /etc/os-release ]] && RASPI_OS_VERSION=$(cat /etc/os-release | grep \\\"^VERSION_ID=\\\" | cut -d\\\\\\\" -f2)";
print "if [[ $\{RASPI_OS_VERSION%%.*\} -gt 9 ]]; then PYTHON_VERSION=\\\"3.9.2\\\"; fi";
next
}
{ print $0 } '`

/**
 * Method that amends the current Raspi setup script to run on Ubuntu platform
 * Used for Alpha/Beta setup for an Ubuntu Dev build
 * @param {*} cmd the current Raspi setup command
 */
export function getUbuntuSetupCmd(cmd) {
  try {
    /**
     * Split current Raspi setup command using delimiter '|'
     * "curl -sL 'https://abes-things-certificates.....&X-Amz-SignedHeaders=host' | bash -"
     */
    let splitCmd = split(cmd, '|');

    if (splitCmd.length !== 2) {
      throw new Error('Error splitting the input command');
    }

    /**
     * Build the Ubuntu setup command using the current Raspi setup command and the amend command that modifies the script for Ubuntu
     * "sudo apt-get -y install curl && curl -sL 'https://abes-things-certificates.....&X-Amz-SignedHeaders=host' | <<AMEND_CMD>> | sudo bash -"
     */
    return join(
        ['sudo apt-get -y install curl', // Fresh Ubuntu setup requires curl installation
        '&&',
        splitCmd[0], // Setup script retrieval URL
        '|',
        AMEND_CMD, // Add the amend command that modifies the script for Ubuntu
        '|',
        splitCmd[1]], // sudo bash -
    AppConstants.WHITESPACE);

  } catch (err) {
    logToConsole('getUbuntuSetupCmd(): Ubuntu command generation failed for cmd [' + cmd + '] with [' + err + ']');
  }
  return cmd;
}

/**
 * Method that amends the current Raspi setup script to update Python version based on Raspi OS
 * @param {*} cmd the current Raspi setup command
 */
export function getSetupCmd(cmd) {
  try {
    /**
     * Split current Raspi setup command using delimiter '|'
     * "curl -sL 'https://abes-things-certificates.....&X-Amz-SignedHeaders=host' | bash -"
     */
    let splitCmd = split(cmd, '|');

    if (splitCmd.length !== 2) {
      throw new Error('Error splitting the input command');
    }

    /**
     * Build the setup command using the current Raspi setup command and the amend command that modifies the script for python version
     * "curl -sL 'https://abes-things-certificates.....&X-Amz-SignedHeaders=host' | <<UPDATE_PYTHON_VERSION_CMD>> | sudo bash -"
     */
    return join(
      [
        splitCmd[0], // Setup script retrieval URL
        '|',
        UPDATE_PYTHON_VERSION_CMD, // Add the amend command that modifies the script for python version
        '|',
        splitCmd[1]], // sudo bash -
      AppConstants.WHITESPACE);

  } catch (err) {
    logToConsole('getSetupCmd(): command generation failed for cmd [' + cmd + '] with [' + err + ']');
  }
  return cmd;
}
