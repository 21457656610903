import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import AppConstants from '../../_Constants/AppConstants';
import { Box, Button, Container, Grid, Header, SpaceBetween, FormField, Input }
  from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import { logToConsole } from '../../Util';

const PASSWORD_MISMATCH_ERROR = 'Passwords entered are either empty or does not match';

class ChangeInitialPassword extends React.Component {

  state = {
    newpassword: AppConstants.EMPTY,
    confirmpassword: AppConstants.EMPTY,
    error: AppConstants.EMPTY
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.detail.value, error: AppConstants.EMPTY });
  }

  handleChangePasswordSubmit = () => {
    if (!this.state.confirmpassword || this.state.newpassword !== this.state.confirmpassword) {
        this.setState({ error: PASSWORD_MISMATCH_ERROR });
    } else {
        Auth.completeNewPassword(this.props.params.user, this.state.newpassword, {})
        .then(response => {
            logToConsole('Temporary password changed successfully');
            Auth.signOut()
            .then(signOutResponse => {
              logToConsole('User signed out successfully');
            })
            .catch( err => {
              var message;
              if (typeof err === 'string') {
                message = err;
              } else {
                message = err.message;
              }
              logToConsole('Error signing out user ' + message);
            });
            this.props.backtologin({});
        })
        .catch( err => {
           var message;
           if (typeof err === 'string') {
             message = err;
           } else {
             message = err.message;
           }
           this.setState({ error: message });
           logToConsole('Error changing passoword: ' + message);
        });
     }
  }

  changeInitialPasswordForm() {
    return (
      <SpaceBetween direction="vertical" size="m">
        <FormField label={<b>New password</b>}>
          <Input
            disableBrowserAutocorrect
            type="password"
            value={this.state.newpassword}
            onChange={this.handleChange('newpassword')}
          />
        </FormField>
        <FormField label={<b>Confirm new password</b>}>
          <Input
            disableBrowserAutocorrect
            type="password"
            value={this.state.confirmpassword}
            onChange={this.handleChange('confirmpassword')}
          />
          </FormField>
          <Button fullWidth
            variant="primary"
            onClick={this.handleChangePasswordSubmit}>
            Submit
          </Button>
        <FormField errorText={this.state.error}/>
      </SpaceBetween>
    )
  }

  render() {
    return(
      <Grid gridDefinition={[{ colspan: 12 }, { colspan: 12 }]}>
        <div style={{ height: "10vh" }}></div>
        <div style={{ width: "400px", margin: "0 auto" }}>
          <SpaceBetween direction="vertical" size="xl">
            <Box textAlign="center" variant="h1">Open Automation Kit</Box>
              <Container>
                <SpaceBetween direction="vertical" size="m">
                  <Header variant="h1">Change password</Header>
                     { this.changeInitialPasswordForm() }
                </SpaceBetween>
              </Container>
            </SpaceBetween>
        </div>
      </Grid>
    )
  }
}

ChangeInitialPassword.propTypes = {
    params: PropTypes.object.isRequired,
    backtologin: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
};

export default ChangeInitialPassword;
